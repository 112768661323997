import React from 'react';
import PropTypes from 'prop-types';

// Misc
import { useSnackbar } from 'notistack';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';

// Material UI - Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

// Local Components
import ConfirmExit from '../../components/ConfirmExit';

// Config
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  menuContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '75%',
  },
  menuRowRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  menuItem: {
    display: 'flex',
    margin: theme.spacing(1),
    flex: 1,
  },
  menuButton: {
    display: 'flex',
    margin: theme.spacing(1),
    flex: 1,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const steps = [
  { title: 'Set Sponsor Settings', content: 'Select campaign settings..', optional: false },
  { title: 'Review Sponsor', content: 'Select campaign settings..', optional: false },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const DialogTitle = withStyles(useStyles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

function NewSponsorDialog(props) {
  const classes = useStyles();
  const { dialogOpen, setDialogOpen, createSponsor } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [confirmExitOpen, setConfirmExitOpen] = React.useState(false);

  // Sponsor Options
  const [companyName, setCompanyName] = React.useState(null);
  const [companyLogo, setCompanyLogo] = React.useState(null);
  const [companyLogoPreviewUrl, setCompanyLogoPreviewUrl] = React.useState(null);
  const [companyWebsiteUrl, setCompanyWebsiteUrl] = React.useState(null);
  const [companyPhoneNumber, setCompanyPhoneNumber] = React.useState(null);
  const [primaryContactName, setPrimaryContactName] = React.useState(null);
  const [primaryContactPhoneNumber, setPrimaryContactPhoneNumber] = React.useState(null);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 0 && companyName === null) {
      enqueueSnackbar('Error: Enter a valid Company Name.', { variant: 'error' });
      return;
    }

    if (activeStep === 0 && companyLogo === null) {
      enqueueSnackbar('Error: Upload a valid Company Logo.', { variant: 'error' });
      return;
    }

    if (activeStep === 0 && companyLogo && companyLogo.status === 'UPLOADING') {
      enqueueSnackbar('Error: Wait for the Company Logo to finish uploading.', {
        variant: 'error',
      });
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompanyName(null);
    setCompanyLogo(null);
    setCompanyLogoPreviewUrl(null);
    setCompanyWebsiteUrl(null);
    setCompanyPhoneNumber(null);
    setPrimaryContactName(null);
    setPrimaryContactPhoneNumber(null);
  };

  const handleConfirmExitCheck = () => {
    setConfirmExitOpen(true);
  };

  const handleConfirmExitConfirm = () => {
    setConfirmExitOpen(false);
    handleReset();
    setDialogOpen(false);
  };

  const handleConfirmExitCancel = () => {
    setConfirmExitOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false);
      }}
      TransitionComponent={Transition}
    >
      <ConfirmExit
        confirmExitOpen={confirmExitOpen}
        handleConfirmExitCancel={handleConfirmExitCancel}
        handleConfirmExitConfirm={handleConfirmExitConfirm}
      />
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleConfirmExitCheck}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Create Sponsor
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (step.optional) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={step.title} {...stepProps}>
                <StepLabel {...labelProps}>{step.title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {/* Step 1 */}
        {activeStep === 0 && (
          <Container className={classes.menuContentRoot}>
            <Container className={classes.menuRowRoot}>
              <TextField
                label="Company Name"
                className={classes.menuItem}
                variant="outlined"
                value={companyName}
                onChange={(event) => {
                  setCompanyName(event.target.value);
                }}
              />
            </Container>

            {companyLogo === null ? (
              <Container className={classes.menuRowRoot}>
                <input
                  accept="image/png, image/jpeg"
                  id="contained-button-file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) {
                      enqueueSnackbar('Error: Unable to access Image.', { variant: 'error' });
                      return;
                    }

                    const file = e.target.files[0];

                    try {
                      getAccessTokenSilently().then((token) => {
                        fetch(`${config.api.URL}/admin/generate-presigned-url`, {
                          method: 'POST',
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                          body: JSON.stringify({
                            file_name: file.name,
                            file_type: file.type,
                          }),
                        })
                          .then((res) => {
                            return res.json();
                          })
                          .then((json) => {
                            const { fields } = json;

                            const formData = new FormData();

                            formData.append('key', fields.key);
                            formData.append('policy', fields.policy);
                            formData.append('x-amz-algorithm', fields['x-amz-algorithm']);
                            formData.append('x-amz-credential', fields['x-amz-credential']);
                            formData.append('x-amz-date', fields['x-amz-date']);
                            formData.append('x-amz-security-token', fields['x-amz-security-token']);
                            formData.append('x-amz-signature', fields['x-amz-signature']);
                            formData.append('Content-Type', fields['Content-Type']);
                            formData.append('ACL', fields.ACL);
                            formData.append('Expires', fields.Expires);
                            formData.append('file', file);

                            fetch(json.url, {
                              method: 'POST',
                              body: formData,
                            }).then(() => {
                              setCompanyLogo({
                                status: 'SUCCESS',
                                url: `${config.assets.URL}/${fields.key}`,
                                file,
                              });
                            });
                          });
                      });
                    } catch (e) {
                      enqueueSnackbar(`Error: ${e}`, {
                        variant: 'error',
                        persist: true,
                      });
                      loginWithRedirect();
                    }

                    setCompanyLogoPreviewUrl(URL.createObjectURL(e.target.files[0]));
                    setCompanyLogo({ status: 'UPLOADING', file });
                  }}
                />
                <label htmlFor="contained-button-file" className={classes.menuItem}>
                  <Button
                    className={classes.menuButton}
                    style={{ margin: 0 }}
                    variant="contained"
                    component="span"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Company Logo
                  </Button>
                </label>
              </Container>
            ) : (
              <Container className={classes.menuRowRoot}>
                <img
                  src={companyLogoPreviewUrl}
                  alt="Company Logo"
                  style={{ height: '56px', margin: '8px' }}
                />
                <FormControl variant="outlined" className={classes.menuItem}>
                  <InputLabel htmlFor="outlined-adornment-password">Offer Image</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    value={companyLogo.file.name ? companyLogo.file.name : ''}
                    disabled
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setCompanyLogo(null);
                          }}
                          edge="end"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                  {companyLogo.status === 'UPLOADING' && (
                    <LinearProgress style={{ marginTop: '16px', marginBotton: '16px' }} />
                  )}
                </FormControl>
              </Container>
            )}

            <Container className={classes.menuRowRoot}>
              <TextField
                label="Company Website URL"
                className={classes.menuItem}
                variant="outlined"
                value={companyWebsiteUrl}
                helperText="Optional"
                onChange={(event) => {
                  setCompanyWebsiteUrl(event.target.value);
                }}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <TextField
                label="Company Phone Number"
                className={classes.menuItem}
                variant="outlined"
                value={companyPhoneNumber}
                helperText="Optional"
                onChange={(event) => {
                  setCompanyPhoneNumber(event.target.value);
                }}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <TextField
                label="Primary Contact Name"
                className={classes.menuItem}
                variant="outlined"
                value={primaryContactName}
                helperText="Optional"
                onChange={(event) => {
                  setPrimaryContactName(event.target.value);
                }}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <TextField
                label="Primary Contact Phone Number"
                className={classes.menuItem}
                variant="outlined"
                value={primaryContactPhoneNumber}
                helperText="Optional"
                onChange={(event) => {
                  setPrimaryContactPhoneNumber(event.target.value);
                }}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Container>
        )}
        {/* Step 2 */}
        {activeStep === 1 && (
          <Container className={classes.menuContentRoot}>
            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={() => {
                  const sponsor = {
                    image_url: companyLogo.url,
                    company_name: companyName,
                  };

                  if (companyPhoneNumber) {
                    sponsor.company_phone_number = companyPhoneNumber;
                  }
                  if (primaryContactName) {
                    sponsor.contact_name = primaryContactName;
                  }
                  if (primaryContactPhoneNumber) {
                    sponsor.contact_phone_number = primaryContactPhoneNumber;
                  }
                  if (companyWebsiteUrl) {
                    sponsor.website_url = companyWebsiteUrl;
                  }
                  createSponsor(sponsor);
                  handleReset();
                  setDialogOpen(false);
                }}
              >
                Create Sponsor
              </Button>
            </Container>
          </Container>
        )}
      </Container>
    </Dialog>
  );
}

NewSponsorDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  createSponsor: PropTypes.func.isRequired,
};

export default NewSponsorDialog;

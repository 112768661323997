import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Misc
import { useSnackbar } from 'notistack';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';

// Material UI - Lab
import Autocomplete from '@material-ui/lab/Autocomplete';

// Material UI - Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { searchSponsors, selectSponsorsSearchResults } from '../sponsors/sponsorsSlice';

// Local Components
import ConfirmExit from '../../components/ConfirmExit';

// Config
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  menuContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '75%',
  },
  menuRowRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  menuItem: {
    display: 'flex',
    margin: theme.spacing(1),
    flex: 1,
  },
  menuButton: {
    display: 'flex',
    margin: theme.spacing(1),
    flex: 1,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const steps = [
  { title: 'Select Sweepstakes Sponsor', content: 'Select campaign settings..', optional: false },
  { title: 'Set Sweepstakes Settings', content: 'Select campaign settings..', optional: false },
  { title: 'Review Sweepstakes', content: 'Select campaign settings..', optional: false },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const DialogTitle = withStyles(useStyles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

function NewSweepstakesDialog(props) {
  const classes = useStyles();
  const { dialogOpen, setDialogOpen, createSweepstakes, token } = props;

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [confirmExitOpen, setConfirmExitOpen] = React.useState(false);

  // Sponsor Options
  const sponsors = useSelector(selectSponsorsSearchResults);
  const sponsorsStatus = useSelector((state) => state.sponsors.status);
  const sponsorsLoading = sponsorsStatus === 'loading';
  const [sponsorValue, setSponsorValue] = React.useState(null);
  const [sponsorInputValue, setSponsorInputValue] = React.useState('');

  // Sweepstakes Options
  const [sweepstakesTitle, setSweepstakesTitle] = React.useState(null);
  const [sweepstakesOfficialRulesSummary, setSweepstakesOfficialRulesSummary] = React.useState(
    null,
  );
  const [sweepstakesImage, setSweepstakesImage] = React.useState(null);
  const [sweepstakesImagePreviewUrl, setSweepstakesImagePreviewUrl] = React.useState(null);
  const [sweepstakesDetails, setSweepstakesDetails] = React.useState(null);

  const handleSearchSponsors = useCallback(
    (q) => {
      try {
        dispatch(searchSponsors({ token, enqueueSnackbar, q }));
      } catch (e) {
        enqueueSnackbar(`Error: ${e}`, {
          variant: 'error',
          persist: true,
        });
        loginWithRedirect();
      }
    },
    [dispatch, enqueueSnackbar, loginWithRedirect, token],
  );

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 0 && sponsorValue === null) {
      enqueueSnackbar('Error: Select a valid Sponsor.', { variant: 'error' });
      return;
    }

    if (activeStep === 1 && sweepstakesTitle === null) {
      enqueueSnackbar('Error: Enter a valid Title.', { variant: 'error' });
      return;
    }

    if (activeStep === 1 && sweepstakesOfficialRulesSummary === null) {
      enqueueSnackbar('Error: Enter a valid Official Rules Summary.', { variant: 'error' });
      return;
    }

    if (activeStep === 1 && sweepstakesDetails === null) {
      enqueueSnackbar('Error: Enter valid Sweepstakes Details.', { variant: 'error' });
      return;
    }

    if (activeStep === 1 && sweepstakesImage === null) {
      enqueueSnackbar('Error: Upload a valid Sweepstakes Image.', { variant: 'error' });
      return;
    }

    if (activeStep === 1 && sweepstakesImage && sweepstakesImage.status === 'UPLOADING') {
      enqueueSnackbar('Error: Wait for the Sweepstakes Image to finish uploading.', {
        variant: 'error',
      });
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    handleSearchSponsors('');

    // Sweepstakes Settings
    setSweepstakesTitle(null);
    setSweepstakesOfficialRulesSummary(null);
    setSweepstakesDetails(null);
    setSweepstakesImage(null);
    setSweepstakesImagePreviewUrl(null);
    // Step
    setActiveStep(0);
  };

  const handleConfirmExitCheck = () => {
    setConfirmExitOpen(true);
  };

  const handleConfirmExitConfirm = () => {
    setConfirmExitOpen(false);
    handleReset();
    setDialogOpen(false);
  };

  const handleConfirmExitCancel = () => {
    setConfirmExitOpen(false);
  };

  useEffect(() => {
    if (token !== null) {
      handleSearchSponsors('');
    }
  }, [handleSearchSponsors, token]);

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false);
      }}
      TransitionComponent={Transition}
    >
      <ConfirmExit
        confirmExitOpen={confirmExitOpen}
        handleConfirmExitCancel={handleConfirmExitCancel}
        handleConfirmExitConfirm={handleConfirmExitConfirm}
      />
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleConfirmExitCheck}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Create Sweepstakes
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (step.optional) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={step.title} {...stepProps}>
                <StepLabel {...labelProps}>{step.title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {/* Step 1 */}
        {activeStep === 0 && (
          <Container className={classes.menuContentRoot}>
            <Container className={classes.menuRowRoot}>
              <Autocomplete
                id="combo-box-demo"
                className={classes.menuItem}
                options={sponsors}
                // options={
                //   organization
                //     ? sponsors.concat([{ company_name: `${organization.name} (default)` }])
                //     : sponsors
                // }
                getOptionLabel={(sponsor) => sponsor.company_name}
                value={sponsorValue}
                onChange={(event, newValue) => {
                  setSponsorValue(newValue);
                }}
                inputValue={sponsorInputValue}
                onInputChange={(event, newInputValue) => {
                  setSponsorInputValue(newInputValue);
                  handleSearchSponsors(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Sponsor"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {sponsorsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                variant="outlined"
                disabled
                // onClick={() => createCampaign({ status: 'Pending' })}
              >
                Create New Sponsor
              </Button>
            </Container>
            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Container>
        )}
        {/* Step 2 */}
        {activeStep === 1 && (
          <Container className={classes.menuContentRoot}>
            <Container className={classes.menuRowRoot}>
              <TextField
                label="Title"
                className={classes.menuItem}
                variant="outlined"
                value={sweepstakesTitle}
                onChange={(event) => {
                  setSweepstakesTitle(event.target.value);
                }}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <TextField
                label="Official Rules Summary"
                className={classes.menuItem}
                variant="outlined"
                value={sweepstakesOfficialRulesSummary}
                onChange={(event) => {
                  setSweepstakesOfficialRulesSummary(event.target.value);
                }}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <TextField
                label="Sweepstakes Details"
                className={classes.menuItem}
                variant="outlined"
                value={sweepstakesDetails}
                onChange={(event) => {
                  setSweepstakesDetails(event.target.value);
                }}
              />
            </Container>

            {sweepstakesImage === null ? (
              <Container className={classes.menuRowRoot}>
                <input
                  accept="image/png, image/jpeg"
                  id="contained-button-file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) {
                      enqueueSnackbar('Error: Unable to access Image.', { variant: 'error' });
                      return;
                    }

                    const file = e.target.files[0];

                    if (file.size >= 5000000) {
                      enqueueSnackbar('Error: Please upload an image smaller than 5 MB.', {
                        variant: 'error',
                      });
                      setSweepstakesImage(null);
                      setSweepstakesImagePreviewUrl(null);
                      return;
                    }

                    try {
                      getAccessTokenSilently().then((token) => {
                        fetch(`${config.api.URL}/admin/generate-presigned-url`, {
                          method: 'POST',
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                          body: JSON.stringify({
                            file_name: file.name,
                            file_type: file.type,
                          }),
                        })
                          .then((res) => {
                            return res.json();
                          })
                          .then((json) => {
                            const { fields } = json;

                            const formData = new FormData();

                            formData.append('key', fields.key);
                            formData.append('policy', fields.policy);
                            formData.append('x-amz-algorithm', fields['x-amz-algorithm']);
                            formData.append('x-amz-credential', fields['x-amz-credential']);
                            formData.append('x-amz-date', fields['x-amz-date']);
                            formData.append('x-amz-security-token', fields['x-amz-security-token']);
                            formData.append('x-amz-signature', fields['x-amz-signature']);
                            formData.append('Content-Type', fields['Content-Type']);
                            formData.append('ACL', fields.ACL);
                            formData.append('Expires', fields.Expires);
                            formData.append('file', file);

                            fetch(json.url, {
                              method: 'POST',
                              body: formData,
                            }).then(() => {
                              setSweepstakesImage({
                                status: 'SUCCESS',
                                url: `${config.assets.URL}/${fields.key}`,
                                file,
                              });
                            });
                          });
                      });
                    } catch (e) {
                      enqueueSnackbar(`Error: ${e}`, {
                        variant: 'error',
                        persist: true,
                      });
                      loginWithRedirect();
                    }

                    setSweepstakesImagePreviewUrl(URL.createObjectURL(e.target.files[0]));
                    setSweepstakesImage({ status: 'UPLOADING', file });
                  }}
                />
                <label htmlFor="contained-button-file" className={classes.menuItem}>
                  <Button
                    className={classes.menuButton}
                    style={{ margin: 0 }}
                    variant="contained"
                    component="span"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Sweepstakes Image
                  </Button>
                </label>
              </Container>
            ) : (
              <Container className={classes.menuRowRoot}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={sweepstakesImagePreviewUrl} style={{ height: '56px', margin: '8px' }} />
                <FormControl variant="outlined" className={classes.menuItem}>
                  <InputLabel htmlFor="outlined-adornment-password">Sweepstakes Image</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    value={sweepstakesImage.file.name ? sweepstakesImage.file.name : ''}
                    disabled
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setSweepstakesImage(null);
                          }}
                          edge="end"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={150}
                  />
                  {sweepstakesImage.status === 'UPLOADING' && (
                    <LinearProgress style={{ marginTop: '16px', marginBotton: '16px' }} />
                  )}
                </FormControl>
              </Container>
            )}

            {/* <Container className={classes.menuRowRoot}> */}
            {/*  <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
            {/*    <Grid container justify="space-around"> */}
            {/*      <KeyboardDatePicker */}
            {/*        className={classes.menuItem} */}
            {/*        disableToolbar */}
            {/*        disabled={startNow} */}
            {/*        variant="inline" */}
            {/*        format="MM/dd/yyyy" */}
            {/*        margin="normal" */}
            {/*        id="date-picker-inline" */}
            {/*        label="Campaign Start Date" */}
            {/*        value={selectedStartDate} */}
            {/*        onChange={handleStartDateChange} */}
            {/*        KeyboardButtonProps={{ */}
            {/*          'aria-label': 'change date', */}
            {/*        }} */}
            {/*      /> */}
            {/*    </Grid> */}
            {/*  </MuiPickersUtilsProvider> */}
            {/* </Container> */}

            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Container>
        )}
        {/* Step 3 */}
        {activeStep === 2 && (
          <Container className={classes.menuContentRoot}>
            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={() => {
                  createSweepstakes({
                    // Nullable Fields
                    title: sweepstakesTitle,
                    sponsor_id: sponsorValue.id,
                    // official_rules_url:
                    sweepstakes_image_url: sweepstakesImage.url,
                    official_rules_summary: sweepstakesOfficialRulesSummary,
                    details: sweepstakesDetails,
                    // terms:
                  });
                  handleReset();
                  setDialogOpen(false);
                }}
              >
                Create Sweepstakes
              </Button>
            </Container>
          </Container>
        )}
      </Container>
    </Dialog>
  );
}

NewSweepstakesDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  createSweepstakes: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default NewSweepstakesDialog;

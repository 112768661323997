import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import campaignsReducer from '../features/campaigns/campaignsSlice';
import offersReducer from '../features/offers/offersSlice';
import sweepstakesReducer from '../features/sweepstakes/sweepstakesSlice';
import sponsorsReducer from '../features/sponsors/sponsorsSlice';
import settingsReducer from '../features/settings/settingsSlice';
import authReducer from '../features/auth/authSlice';

const rootPersistConfig = {
  key: 'root',
  whitelist: ['settings'],
  storage,
};

const authPersistConfig = {
  key: 'auth',
  whitelist: ['selectedOrganizationId'],
  storage,
};

const reducers = combineReducers({
  campaigns: campaignsReducer,
  offers: offersReducer,
  sweepstakes: sweepstakesReducer,
  sponsors: sponsorsReducer,
  settings: settingsReducer,
  auth: persistReducer(authPersistConfig, authReducer),
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});
export const persistor = persistStore(store);

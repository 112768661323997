import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#678B46',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
    },
    tertinary: {
      main: '#393A3D',
    },
    accent: {
      main: '#D8D8D8',
    },
  },
  // overrides: {
  //   // Style sheet name ⚛️
  //   MuiListItem: {
  //     // Name of the rule
  //     root: {
  //       "&$selected": {
  //         backgroundColor: "black",
  //         "&:hover": {
  //           backgroundColor: "black",
  //         },
  //       },
  //     },
  //     button: {
  //       "&:hover": {
  //         backgroundColor: "#2A2A2A",
  //       },
  //     },
  //   },
  // },
});

import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Misc Imports
import { useSnackbar } from 'notistack';
import { BrowserView, MobileView } from 'react-device-detect';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { selectAllOffers, createOffer, deleteOffers, fetchOffers } from './offersSlice';

// Local Components
import SubHeader from '../../components/SubHeader';
import DataTable from '../../components/DataTable';
import Headline from '../../components/Headline';
import NewOfferDialog from './NewOfferDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  r_root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    justifyContent: 'flex-start',
  },
  r_content: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
  },
  r_headline: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

function OfferDashboard(props) {
  const { organization, token } = props;
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { loginWithRedirect } = useAuth0();

  const dispatch = useDispatch();

  const offers = useSelector(selectAllOffers);
  const totalOffersCount = useSelector((state) => state.offers.total_count);
  const totalActiveOffersCount = useSelector((state) => state.offers.total_active_count);
  const totalInactiveOffersCount = useSelector((state) => state.offers.total_inactive_count);
  const rowsPerPage = useSelector((state) => state.settings.rowsPerPage);

  const status = useSelector((state) => state.offers.status);

  const [pendingCreation, setPendingCreation] = useState(false);

  const loading =
    status === 'idle' || status === 'loading' || status === 'failed' || pendingCreation;

  const [dialogOpen, setDialogOpen] = useState(false);

  const headCells = [
    { id: 'id', type: 'number', disablePadding: true, label: 'ID' },
    { id: 'title', type: 'string', disablePadding: false, label: 'Title' },
    { id: 'status', type: 'string', disablePadding: false, label: 'Status' },
    {
      id: 'expiration_date',
      type: 'date',
      disablePadding: false,
      label: 'Expiration Date',
    },
    {
      parent: 'sponsor',
      id: 'company_name',
      type: 'string',
      disablePadding: false,
      label: 'Sponsor',
    },
    { id: 'offer_link', type: 'string', disablePadding: false, label: 'Offer Link' },
    { id: 'space', disablePadding: false },
  ];

  const mobileHeadCells = [
    { id: 'id', type: 'number', disablePadding: true, label: 'ID' },
    { id: 'title', type: 'string', disablePadding: false, label: 'Title' },
    { id: 'status', type: 'string', disablePadding: false, label: 'Status' },
    { id: 'space', disablePadding: false },
  ];

  const dense = false;

  const handleCreateOffer = (
    body,
    page = 1,
    pageSize = rowsPerPage,
    sort = 'desc',
    column = 'id',
  ) => {
    setPendingCreation(true);
    try {
      dispatch(createOffer({ token, body, enqueueSnackbar, page, pageSize, sort, column }));
      setPendingCreation(false);
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  const handleDeleteOffers = (ids) => {
    try {
      dispatch(deleteOffers({ token, ids, enqueueSnackbar }));
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  const handleLoadOffers = useCallback(
    (page = 1, pageSize = rowsPerPage, sort = 'desc', column = 'id') => {
      try {
        dispatch(fetchOffers({ token, enqueueSnackbar, page, pageSize, sort, column }));
      } catch (e) {
        enqueueSnackbar(`Error: ${e}`, {
          variant: 'error',
          persist: true,
        });
        loginWithRedirect();
      }
    },
    [dispatch, enqueueSnackbar, loginWithRedirect, rowsPerPage, token],
  );

  useEffect(() => {
    if (token !== null) {
      handleLoadOffers();
    }
  }, [handleLoadOffers, token]);

  return (
    <Box className={classes.root}>
      <NewOfferDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        createOffer={handleCreateOffer}
        token={token}
      />
      <Box className={classes.r_content}>
        <SubHeader fabTitle="New Offer" setDialogOpen={setDialogOpen} organization={organization} />
        <Box className={classes.r_headline}>
          <Headline
            title="Active Offers"
            value={totalActiveOffersCount}
            loading={totalActiveOffersCount == null}
          />
          <Headline
            title="Inactive Offers"
            value={totalInactiveOffersCount}
            loading={totalInactiveOffersCount == null}
          />
        </Box>
        <BrowserView>
          <DataTable
            title="All Offers"
            loading={loading}
            dense={dense}
            rows={offers}
            totalCount={totalOffersCount}
            headCells={headCells}
            deleteRows={handleDeleteOffers}
            loadRows={handleLoadOffers}
            previewUrlPrefix="/offers/"
            defaultSortDirection="desc"
          />
        </BrowserView>
        <MobileView>
          <DataTable
            title="All Offers"
            loading={loading}
            dense={dense}
            rows={offers}
            totalCount={totalOffersCount}
            headCells={mobileHeadCells}
            deleteRows={handleDeleteOffers}
            loadRows={handleLoadOffers}
            previewUrlPrefix="/offers/"
            defaultSortDirection="desc"
          />
        </MobileView>
      </Box>
    </Box>
  );
}

OfferDashboard.propTypes = {
  organization: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default OfferDashboard;

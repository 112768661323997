import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Material UI - Core
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';

// Material UI - Icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

function ShareDialog(props) {
  // const { confirmExitOpen, handleConfirmExitCancel, handleConfirmExitConfirm } = props;
  const { dialogOpen, onClose, url } = props;

  const [copySuccess, setCopySuccess] = useState('');

  const textFieldRef = useRef(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setCopySuccess('Copied!');
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        setCopySuccess('');
        onClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Share Your Campaign</DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextField
          disabled
          variant="outlined"
          label="Direct URL"
          value={url}
          style={{ display: 'flex', width: '400px', marginBottom: '16px' }}
          inputRef={textFieldRef}
        />
        <Box
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            style={{ display: 'flex' }}
            variant="contained"
            color="primary"
            startIcon={<AssignmentIcon />}
            onClick={copyToClipboard}
          >
            Copy to Clipboard
          </Button>
          <Button
            style={{ display: 'flex' }}
            variant="contained"
            color="primary"
            startIcon={<OpenInNewIcon />}
            onClick={() => window.open(url, '_blank')}
          >
            Open
          </Button>
        </Box>
        {copySuccess && (
          <DialogContentText style={{ marginTop: '16px', marginBottom: '-8px' }}>
            {copySuccess}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleConfirmExitCancel} color="primary"> */}
        {/*  No */}
        {/* </Button> */}
        {/* <Button onClick={handleConfirmExitConfirm} color="primary" autoFocus> */}
        {/*  Yes */}
        {/* </Button> */}
      </DialogActions>
    </Dialog>
  );
}

ShareDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  //   handleConfirmExitConfirm: PropTypes.func.isRequired,
};

export default ShareDialog;

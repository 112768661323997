import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Material UI - Icons
import DeleteIcon from '@material-ui/icons/Delete';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// Material UI - Lab
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { updateRowsPerPage } from '../features/settings/settingsSlice';

// Local Components
// import CampaignPreview from './CampaignPreview';

function DataTableHead(props) {
  const { classes, order, orderBy, onRequestSort, loading, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox */}
          {/*  color="primary" */}
          {/*  indeterminate={numSelected > 0 && numSelected < rowCount} */}
          {/*  checked={rowCount > 0 && numSelected === rowCount} */}
          {/*  onChange={onSelectAllClick} */}
          {/*  inputProps={{ 'aria-label': 'select all' }} */}
          {/* /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {loading ? (
              <Skeleton variant="text" />
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label ? headCell.label : ''}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DataTableHead.propTypes = {
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    flex: '1 1 100%',
  },
}));

const DataTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, title, deleteRows, selected, setSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            color="inherit"
            onClick={() => {
              setSelected([]);
              deleteRows(selected);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
        // <Tooltip title="Filter list">
        //   <IconButton aria-label="filter list" color="inherit">
        //     <FilterListIcon />
        //   </IconButton>
        // </Tooltip>
      )}
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  deleteRows: PropTypes.func,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1),
    maxWidth: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCell: {
    flexBasis: 0,
  },
  logo: {
    height: 100,
    width: 200,
    objectFit: 'contain',
  },
}));

function DataTable(props) {
  const {
    loading,
    dense,
    rows,
    headCells,
    deleteRows,
    loadRows,
    totalCount,
    title,
    previewUrlPrefix,
    defaultSortDirection,
    defaultSortColumn,
    showPreviews,
    shareHandler,
  } = props;

  const dispatch = useDispatch();

  const rowsPerPage = useSelector((state) => state.settings.rowsPerPage);

  const classes = useStyles();
  const history = useHistory();

  const isCompact = true;

  const [order, setOrder] = React.useState(defaultSortDirection);
  const [orderBy, setOrderBy] = React.useState(defaultSortColumn);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [previewOpen, setPreviewOpen] = React.useState(false);
  // const [selectedCampaign, setSelectedCampaign] = React.useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    loadRows(page + 1, rowsPerPage, newOrder, property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleShowPreview = (row) => {
    history.push(`${previewUrlPrefix}${row.id}`);
  };

  const handleSelectRow = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    loadRows(newPage + 1, rowsPerPage, order, orderBy);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(updateRowsPerPage(newRowsPerPage));
    setPage(0);

    loadRows(1, newRowsPerPage, order, orderBy);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = 0; // loading ? 0 : (rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage));

  return (
    <div className={classes.root}>
      {/* {selectedCampaign !== null ? ( */}
      {/*  <CampaignPreview */}
      {/*    previewOpen={previewOpen} */}
      {/*    campaign={selectedCampaign} */}
      {/*    setPreviewOpen={setPreviewOpen} */}
      {/*  /> */}
      {/* ) : null} */}
      <Paper className={classes.paper}>
        <DataTableToolbar
          numSelected={selected.length}
          deleteRows={deleteRows}
          selected={selected}
          setSelected={setSelected}
          title={title}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="data table"
          >
            <DataTableHead
              loading={loading}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {loading
                ? [...Array(rowsPerPage).keys()].map((row) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `data-table-checkbox-${row}`;

                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={row}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        {headCells.map((column) => (
                          <TableCell align="left" key={column.id}>
                            <Skeleton variant="text" />
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
                : // : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  rows.map((row) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `data-table-checkbox-${row.id}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        style={showPreviews ? { cursor: 'pointer' } : {}}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={(event) => handleSelectRow(event, row.id)}
                          />
                        </TableCell>
                        {headCells.map((column) => {
                          return !column.label ? (
                            <TableCell
                              align="left"
                              key={column.id}
                              style={isCompact ? { paddingTop: 0, paddingBottom: 0 } : null}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                {shareHandler && (
                                  <Tooltip title="Share" aria-label="share">
                                    <IconButton
                                      onClick={() => {
                                        shareHandler(row);
                                      }}
                                      color="primary"
                                      aria-label="Share"
                                      component="span"
                                    >
                                      <ShareIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Edit" aria-label="edit">
                                  <IconButton
                                    color="primary"
                                    aria-label="Edit"
                                    component="span"
                                    disabled
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Open" aria-label="open">
                                  <IconButton
                                    color="primary"
                                    aria-label="Open"
                                    component={Link}
                                    to={`${previewUrlPrefix}${row.id}`}
                                  >
                                    <ArrowForwardIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell
                              align="left"
                              key={column.id}
                              onClick={showPreviews && (() => handleShowPreview(row))}
                            >
                              {(function () {
                                if (
                                  'parent' in column &&
                                  'id' in column &&
                                  row[column.parent] &&
                                  column.id in row[column.parent] &&
                                  row[column.parent][column.id] !== null
                                ) {
                                  switch (column.type) {
                                    case 'date':
                                      if (
                                        row[column.parent][column.id] !== null &&
                                        row[column.parent][column.id] !== undefined
                                      ) {
                                        return new Date(
                                          row[column.parent][column.id],
                                        ).toLocaleDateString('en-US');
                                      }
                                      break;
                                    case 'number':
                                      return row[column.parent][column.id];
                                    case 'string':
                                    default:
                                      return row[column.parent][column.id].length > 100
                                        ? `${row[column.parent][column.id].substring(0, 100)}...`
                                        : row[column.parent][column.id];
                                  }
                                } else if (column.id in row && row[column.id] !== null) {
                                  switch (column.type) {
                                    case 'date':
                                      if (row[column.id] !== null && row[column.id] !== undefined) {
                                        return new Date(row[column.id]).toLocaleDateString('en-US');
                                      }
                                      break;
                                    case 'number':
                                      return row[column.id];
                                    case 'string':
                                    default:
                                      return row[column.id].length > 100
                                        ? `${row[column.id].substring(0, 97)}...`
                                        : row[column.id];
                                  }
                                } else {
                                  return '-';
                                }
                              })()}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}

              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {totalCount == null ? (
          <div />
        ) : (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}

DataTable.defaultProps = {
  defaultSortDirection: 'asc',
  defaultSortColumn: 'id',
  showPreviews: true,
};

DataTable.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  dense: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  deleteRows: PropTypes.func,
  totalCount: PropTypes.number.isRequired,
  loadRows: PropTypes.func,
  previewUrlPrefix: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf(['asc', 'desc']),
  defaultSortColumn: PropTypes.string,
  showPreviews: PropTypes.bool,
  shareHandler: PropTypes.func,
};

export default DataTable;

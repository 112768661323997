import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Misc
import { useSnackbar } from 'notistack';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';

// Material UI - Lab
import Autocomplete from '@material-ui/lab/Autocomplete';

// Material UI - Pickers
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// Material UI - Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  resetSearchResults as resetOffersSearchResults,
  searchOffers,
  selectOffersSearchResults,
} from '../offers/offersSlice';
import {
  resetSearchResults as resetSweepstakesSearchResults,
  searchSweepstakes,
  selectSweepstakesSearchResults,
} from '../sweepstakes/sweepstakesSlice';
import { searchSponsors, selectSponsorsSearchResults } from '../sponsors/sponsorsSlice';

// Local Components
import ConfirmExit from '../../components/ConfirmExit';

// Config
import config from '../../config';

const MAX_PROMPT_LENGTH = 150;
const MAX_CHOICE_LENGTH = 100;
const MAX_TITLE_LENGTH = 128;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  menuContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '75%',
  },
  menuRowRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  menuItem: {
    display: 'flex',
    margin: theme.spacing(1),
    flex: 1,
  },
  menuButton: {
    display: 'flex',
    margin: theme.spacing(1),
    flex: 1,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const steps = [
  { title: 'Select Sponsor', optional: false },
  { title: 'Select Offer', optional: false },
  { title: 'Set Question', optional: false },
  { title: 'Campaign Settings', optional: false },
  { title: 'Advanced Settings', optional: true },
  { title: 'Set Schedule', optional: false },
  { title: 'Review', optional: false },
];

// eslint-disable-next-line func-names
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const DialogTitle = withStyles(useStyles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

function NewCampaignDialog(props) {
  const classes = useStyles();
  const { dialogOpen, setDialogOpen, createCampaign, token } = props;

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [confirmExitOpen, setConfirmExitOpen] = React.useState(false);

  // Sponsor Options
  const sponsors = useSelector(selectSponsorsSearchResults);
  const sponsorsStatus = useSelector((state) => state.sponsors.status);
  const sponsorsLoading = sponsorsStatus === 'loading';
  const [sponsorValue, setSponsorValue] = React.useState(null);
  const [sponsorInputValue, setSponsorInputValue] = React.useState('');

  // Offer Options
  const offers = useSelector(selectOffersSearchResults);
  const offersStatus = useSelector((state) => state.offers.status);
  const offersLoading = offersStatus === 'loading';
  const [offerValue, setOfferValue] = React.useState(null);
  const [offerInputValue, setOfferInputValue] = React.useState('');

  // Sweepstakes Options
  const sweepstakes = useSelector(selectSweepstakesSearchResults);
  const sweepstakesStatus = useSelector((state) => state.sweepstakes.status);
  const sweepstakesLoading = sweepstakesStatus === 'loading';
  const [sweepstakesValue, setSweepstakesValue] = React.useState(null);
  const [sweepstakesInputValue, setSweepstakesInputValue] = React.useState('');

  // Campaign Options
  const [campaignType, setCampaignType] = React.useState(null);
  const [campaignTypeInputValue, setCampaignTypeInputValue] = React.useState('');
  const [campaignTitle, setCampaignTitle] = React.useState('');
  const [campaignPrompt, setCampaignPrompt] = React.useState('');
  const [campaignChoices, setCampaignChoices] = React.useState(['', '']);
  const [correctChoice, setCorrectChoice] = React.useState(0);
  const [featuredImage, setFeaturedImage] = React.useState(null);
  const [featuredImagePreviewUrl, setFeaturedImagePreviewUrl] = React.useState(null);

  const [entryRestrictionPolicy, setEntryRestrictionPolicy] = React.useState('NO_CHECK');
  const [entryRestrictionPolicyInputValue, setEntryRestrictionPolicyInputValue] = React.useState(
    '',
  );

  const [presentOfferPolicy, setPresentOfferPolicy] = React.useState('SHOW_ALL');
  const [presentOfferPolicyInputValue, setPresentOfferPolicyInputValue] = React.useState('');

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    new Date(new Date(Date.now()).toDateString()),
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    new Date(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toDateString()),
  );
  const [startNow, setStartNow] = React.useState(true);
  const [noEndDate, setNoEndDate] = React.useState(false);

  const handleSearchSponsors = useCallback(
    (q) => {
      try {
        dispatch(searchSponsors({ token, enqueueSnackbar, q }));
      } catch (e) {
        enqueueSnackbar(`Error: ${e}`, {
          variant: 'error',
          persist: true,
        });
        loginWithRedirect();
      }
    },
    [dispatch, enqueueSnackbar, loginWithRedirect, token],
  );

  const handleSearchOffers = (q, sponsor_id) => {
    try {
      dispatch(searchOffers({ token, enqueueSnackbar, q, sponsor_id }));
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  const handleSearchSweepstakes = (q, sponsor_id) => {
    try {
      dispatch(searchSweepstakes({ token, enqueueSnackbar, q, sponsor_id }));
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  // const isStepOptional = (step) => {
  //   return step === 1;
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 0 && sponsorValue === null) {
      enqueueSnackbar('Error: Select a valid Sponsor.', { variant: 'error' });
      return;
    }

    if (activeStep === 1 && offerValue === null && sweepstakesValue === null) {
      enqueueSnackbar('Error: Select a valid Offer, Sweepstakes, or Both.', { variant: 'error' });
      return;
    }

    if (activeStep === 2 && campaignType === null) {
      enqueueSnackbar('Error: Select a valid Campaign Type.', { variant: 'error' });
      return;
    }

    if (activeStep === 2 && campaignPrompt === '') {
      enqueueSnackbar('Error: Enter a valid Prompt.', { variant: 'error' });
      return;
    }

    if (activeStep === 2 && featuredImage && featuredImage.status === 'UPLOADING') {
      enqueueSnackbar('Error: Wait for the Featured Image to finish uploading.', {
        variant: 'error',
      });
      return;
    }

    if (activeStep === 2 && campaignPrompt.length > MAX_PROMPT_LENGTH) {
      enqueueSnackbar(`Error: Prompt must be less than ${MAX_PROMPT_LENGTH + 1} characters.`, {
        variant: 'error',
      });
      return;
    }

    if (activeStep === 2) {
      for (let i = 0; i < campaignChoices.length; i += 1) {
        if (campaignChoices[i] === '') {
          enqueueSnackbar('Error: 1 or more choices are empty.', { variant: 'error' });
          return;
        }
        if (campaignChoices[i].length > 100) {
          enqueueSnackbar(`Error: Choices must be less than ${MAX_CHOICE_LENGTH + 1} characters.`, {
            variant: 'error',
          });
          return;
        }
      }
    }

    if (activeStep === 3 && campaignTitle.length > MAX_TITLE_LENGTH) {
      enqueueSnackbar(
        `Error: Campaign Title must be less than ${MAX_TITLE_LENGTH + 1} characters.`,
        {
          variant: 'error',
        },
      );
      return;
    }

    if (activeStep === 3 && campaignTitle === null) {
      enqueueSnackbar('Error: Select a valid Campaign Title.', { variant: 'error' });
      return;
    }

    if (activeStep === 4 && presentOfferPolicy === null) {
      enqueueSnackbar('Error: Select a valid Present Offer Policy.', { variant: 'error' });
      return;
    }

    if (activeStep === 4 && entryRestrictionPolicy === null) {
      enqueueSnackbar('Error: Select a valid Entry Restriction Policy.', { variant: 'error' });
      return;
    }

    if (activeStep === 5) {
      if (!noEndDate) {
        if (selectedStartDate.getTime() === selectedEndDate.getTime()) {
          enqueueSnackbar('Error: Start Date cannot be the same as End Date.', {
            variant: 'error',
          });
          return;
        }
        if (selectedStartDate.getTime() > selectedEndDate.getTime()) {
          enqueueSnackbar('Error: Start Date cannot be after End Date.', { variant: 'error' });
          return;
        }
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }
  //
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  const handleReset = () => {
    handleSearchSponsors('');
    dispatch(resetOffersSearchResults());
    dispatch(resetSweepstakesSearchResults());

    // Sponsors
    setSponsorValue(null);
    setSponsorInputValue('');
    // Offers
    setOfferValue(null);
    setOfferInputValue('');
    // Sweepstakes
    setSweepstakesValue(null);
    setSweepstakesInputValue('');
    // Campaign Settings
    setCampaignType(null);
    setCampaignTypeInputValue('');
    setCampaignTitle('');
    setCampaignPrompt('');
    setCampaignChoices(['', '']);
    setPresentOfferPolicy('SHOW_ALL');
    setPresentOfferPolicyInputValue('');
    setEntryRestrictionPolicy('NO_CHECK');
    setEntryRestrictionPolicyInputValue('');
    setSelectedStartDate(new Date(Date.now()));
    setSelectedEndDate(new Date(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toDateString()));
    setStartNow(true);
    setNoEndDate(false);
    // Step
    setActiveStep(0);
  };

  const handleConfirmExitCheck = () => {
    setConfirmExitOpen(true);
  };

  const handleConfirmExitConfirm = () => {
    setConfirmExitOpen(false);
    setDialogOpen(false);
    handleReset();
  };

  const handleConfirmExitCancel = () => {
    setConfirmExitOpen(false);
  };

  const handleStartDateChange = (date) => {
    try {
      setSelectedStartDate(new Date(date.toDateString()));
    } catch (e) {
      console.log(e);
    }
  };

  const handleEndDateChange = (date) => {
    try {
      setSelectedEndDate(new Date(date.toDateString()));
    } catch (e) {
      console.log(e);
    }
  };

  const handleToggleStartNow = () => {
    setStartNow(!startNow);
    handleStartDateChange(new Date(Date.now()));
  };

  const handleToggleNoEndDate = () => {
    setNoEndDate(!noEndDate);
  };

  useEffect(() => {
    if (token !== null) {
      handleSearchSponsors('');
    }
  }, [handleSearchSponsors, token]);

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false);
      }}
      TransitionComponent={Transition}
    >
      <ConfirmExit
        confirmExitOpen={confirmExitOpen}
        handleConfirmExitCancel={handleConfirmExitCancel}
        handleConfirmExitConfirm={handleConfirmExitConfirm}
      />
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleConfirmExitCheck}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Create Campaign
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (step.optional) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={step.title} {...stepProps}>
                <StepLabel {...labelProps}>{step.title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {/* Step 1 */}
        {activeStep === 0 && (
          <Container className={classes.menuContentRoot}>
            <blockquote
              style={{
                margin: '24px 0',
                padding: '4px 24px',
                borderLeft: '5px solid #678B46',
                backgroundColor: 'rgba(100,229,100,0.2)',
              }}
            >
              <p>
                <strong>Select a Sponsor</strong>. The Sponsor selected here will be used to
                populate the Offers and Sweepstakes in the next page. If you don&apos;t have any
                existing Sponsors, you must create one first.
              </p>
            </blockquote>
            <Container className={classes.menuRowRoot}>
              <Autocomplete
                loading={sponsorsLoading}
                className={classes.menuItem}
                options={sponsors}
                // options={
                //   organization
                //     ? sponsors.concat([{ company_name: `${organization.name} (default)` }])
                //     : sponsors
                // }
                getOptionLabel={(sponsor) => sponsor.company_name}
                value={sponsorValue}
                onChange={(event, newValue) => {
                  setSponsorValue(newValue);
                  if (newValue !== null) {
                    handleSearchOffers('', newValue.id);
                    handleSearchSweepstakes('', newValue.id);
                  }
                }}
                inputValue={sponsorInputValue}
                onInputChange={(event, newInputValue) => {
                  setSponsorInputValue(newInputValue);
                  setOfferValue(null);
                  setOfferInputValue('');
                  setSweepstakesValue(null);
                  setSweepstakesInputValue('');
                  handleSearchSponsors(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Sponsor"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {sponsorsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                variant="outlined"
                disabled
                // onClick={() => createCampaign({ status: 'Pending' })}
              >
                Create New Sponsor
              </Button>
            </Container>
            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Container>
        )}
        {/* Step 2 */}
        {activeStep === 1 && (
          <Container className={classes.menuContentRoot}>
            <blockquote
              style={{
                margin: '24px 0',
                padding: '4px 24px',
                borderLeft: '5px solid #678B46',
                backgroundColor: 'rgba(100,229,100,0.2)',
              }}
            >
              <p>
                <strong>Select Offer / Sweepstakes</strong>. Select an Offer, Sweepstakes, or both.
                If both are selected the Sweepstakes entry view will be shown first, then the Offer
                will be shown after the User enters the Sweepstakes. If there are no existing Offers
                or Sweepstakes associated with the selected Sponsor, you must create one first.
              </p>
            </blockquote>
            <Container className={classes.menuRowRoot}>
              <Autocomplete
                loading={offersLoading}
                className={classes.menuItem}
                options={offers}
                getOptionLabel={(offer) => {
                  if ('title' in offer && offer.title !== null) {
                    if (offer.title.length > 50) {
                      return `(#${offer.id}) ${offer.title.substring(0, 47)}...`;
                    }
                    return ` (#${offer.id}) ${offer.title}`;
                  }
                  return ` (#${offer.id})`;
                }}
                value={offerValue}
                onChange={(event, newValue) => {
                  setOfferValue(newValue);
                }}
                inputValue={offerInputValue}
                onInputChange={(event, newInputValue) => {
                  setOfferInputValue(newInputValue);
                  handleSearchOffers(newInputValue, sponsorValue.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Offer"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {offersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                variant="outlined"
                disabled
                // onClick={() => createCampaign({ status: 'Pending' })}
              >
                Create New Offer
              </Button>
            </Container>

            <Container className={classes.menuRowRoot}>
              <Autocomplete
                loading={sweepstakesLoading}
                className={classes.menuItem}
                options={sweepstakes}
                getOptionLabel={(curSweepstakes) => {
                  if ('title' in curSweepstakes && curSweepstakes.title !== null) {
                    if (curSweepstakes.title.length > 50) {
                      return `(#${curSweepstakes.id}) ${curSweepstakes.title.substring(0, 47)}...`;
                    }
                    return ` (#${curSweepstakes.id}) ${curSweepstakes.title}`;
                  }
                  return ` (#${curSweepstakes.id})`;
                }}
                value={sweepstakesValue}
                onChange={(event, newValue) => {
                  setSweepstakesValue(newValue);
                }}
                inputValue={sweepstakesInputValue}
                onInputChange={(event, newInputValue) => {
                  setSweepstakesInputValue(newInputValue);
                  handleSearchSweepstakes(newInputValue, sponsorValue.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Sweepstakes"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {sweepstakesLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                variant="outlined"
                disabled
                // onClick={() => createSweepstakes({ status: 'Pending' })}
              >
                Create New Sweepstakes
              </Button>
            </Container>

            {/* <Container className={classes.menuRowRoot}> */}
            {/*  <Autocomplete */}
            {/*    id="combo-box-demo" */}
            {/*    className={classes.menuItem} */}
            {/*    options={offers.filter((x) => { */}
            {/*      return x.sponsor.company_name === sponsorValue.company_name; */}
            {/*    })} */}
            {/*    getOptionLabel={(sweepstakes) => { */}
            {/*      if ('title' in sweepstakes && sweepstakes.title.length > 50) { */}
            {/*        return `(#${sweepstakes.id}) ${offer.title.substring(0, 47)}...`; */}
            {/*      } */}
            {/*      return ` (#${sweepstakes.id}) ${offer.title}`; */}
            {/*    }} */}
            {/*    value={sweepstakesValue} */}
            {/*    onChange={(event, newValue) => { */}
            {/*      setSweepstakesValue(newValue); */}
            {/*    }} */}
            {/*    inputValue={offerInputValue} */}
            {/*    onInputChange={(event, newInputValue) => { */}
            {/*      setOfferInputValue(newInputValue); */}
            {/*    }} */}
            {/*    renderInput={(params) => ( */}
            {/*      <TextField {...params} label="Select Sweepstakes" variant="outlined" /> */}
            {/*    )} */}
            {/*  /> */}
            {/* </Container> */}

            {/* <Container className={classes.menuRowRoot}> */}
            {/*  <Button */}
            {/*    className={classes.menuButton} */}
            {/*    variant="outlined" */}
            {/*    disabled */}
            {/*    // onClick={() => createCampaign({ status: 'Pending' })} */}
            {/*  > */}
            {/*    Create New Sweepstakes */}
            {/*  </Button> */}
            {/* </Container> */}

            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Container>
        )}
        {/* Step 3 */}
        {activeStep === 2 && (
          <Container className={classes.menuContentRoot}>
            <blockquote
              style={{
                margin: '24px 0',
                padding: '4px 24px',
                borderLeft: '5px solid #678B46',
                backgroundColor: 'rgba(100,229,100,0.2)',
              }}
            >
              <p>
                <strong>Select Question</strong>. First specify the type of Campaign you&apos;re
                looking to run, either a Poll or Trivia. Then you can set the Prompt and optionally
                a Featured Image to display alongside the Prompt. Finally you can specify the number
                and content for each choice, and optionally if you&apos;ve selected a Trivia
                Campaign type you&apos;ll specify which choice is correct.
              </p>
            </blockquote>
            <Container className={classes.menuRowRoot}>
              <Autocomplete
                id="combo-box-demo"
                className={classes.menuItem}
                options={['POLL', 'TRIVIA']}
                value={campaignType}
                onChange={(event, newValue) => {
                  setCampaignType(newValue);
                }}
                inputValue={campaignTypeInputValue}
                onInputChange={(event, newInputValue) => {
                  setCampaignTypeInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Campaign Type" variant="outlined" />
                )}
              />
            </Container>
            <Container className={classes.menuRowRoot}>
              <TextField
                label="Prompt"
                className={classes.menuItem}
                variant="outlined"
                value={campaignPrompt}
                onChange={(event) => {
                  setCampaignPrompt(event.target.value);
                }}
                helperText={`${MAX_PROMPT_LENGTH - campaignPrompt.length} Characters Remaining`}
                error={campaignPrompt.length > MAX_PROMPT_LENGTH}
              />
            </Container>

            {featuredImage === null ? (
              <Container className={classes.menuRowRoot}>
                <input
                  accept="image/png, image/jpeg"
                  id="contained-button-file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) {
                      enqueueSnackbar('Error: Unable to access Image.', { variant: 'error' });
                      return;
                    }

                    const file = e.target.files[0];

                    try {
                      getAccessTokenSilently().then((token) => {
                        fetch(`${config.api.URL}/admin/generate-presigned-url`, {
                          method: 'POST',
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                          body: JSON.stringify({
                            file_name: file.name,
                            file_type: file.type,
                          }),
                        })
                          .then((res) => {
                            return res.json();
                          })
                          .then((json) => {
                            const { fields } = json;

                            const formData = new FormData();

                            formData.append('key', fields.key);
                            formData.append('policy', fields.policy);
                            formData.append('x-amz-algorithm', fields['x-amz-algorithm']);
                            formData.append('x-amz-credential', fields['x-amz-credential']);
                            formData.append('x-amz-date', fields['x-amz-date']);
                            formData.append('x-amz-security-token', fields['x-amz-security-token']);
                            formData.append('x-amz-signature', fields['x-amz-signature']);
                            formData.append('Content-Type', fields['Content-Type']);
                            formData.append('ACL', fields.ACL);
                            formData.append('Expires', fields.Expires);
                            formData.append('file', file);

                            fetch(json.url, {
                              method: 'POST',
                              body: formData,
                            }).then(() => {
                              setFeaturedImage({
                                status: 'SUCCESS',
                                url: `${config.assets.URL}/${fields.key}`,
                                file,
                              });
                            });
                          });
                      });
                    } catch (e) {
                      enqueueSnackbar(`Error: ${e}`, {
                        variant: 'error',
                        persist: true,
                      });
                      loginWithRedirect();
                    }

                    setFeaturedImagePreviewUrl(URL.createObjectURL(e.target.files[0]));
                    setFeaturedImage({ status: 'UPLOADING', file });
                  }}
                />
                <label htmlFor="contained-button-file" className={classes.menuItem}>
                  <Button
                    className={classes.menuButton}
                    style={{ margin: 0 }}
                    variant="contained"
                    component="span"
                    color="primary"
                    helperText="Optional"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Featured Image [Optional]
                  </Button>
                </label>
              </Container>
            ) : (
              <Container className={classes.menuRowRoot}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={featuredImagePreviewUrl} style={{ height: '56px', margin: '8px' }} />
                <FormControl variant="outlined" className={classes.menuItem}>
                  <InputLabel htmlFor="outlined-adornment-password">Featured Image</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    value={featuredImage.file.name ? featuredImage.file.name : ''}
                    disabled
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setFeaturedImage(null);
                          }}
                          edge="end"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={115}
                  />
                  {featuredImage.status === 'UPLOADING' && (
                    <LinearProgress style={{ marginTop: '16px', marginBottom: '16px' }} />
                  )}
                </FormControl>
              </Container>
            )}

            <Divider style={{ width: '90%', margin: '8px' }} />
            {campaignChoices.map((choice, idx) => (
              <Container className={classes.menuRowRoot}>
                {/* <TextField */}
                {/*  label="Choice #1" */}
                {/*  className={classes.menuItem} */}
                {/*  variant="outlined" */}
                {/*  value={choice.value} */}
                {/*  onChange={(event) => { */}
                {/*    const oldChoices = campaignChoices; */}
                {/*    oldChoices[idx] = { */}
                {/*      value: event.target.value, */}
                {/*      isChecked: campaignChoices[idx].isChecked, */}
                {/*    }; */}
                {/*    setCampaignChoices(oldChoices); */}
                {/*  }} */}
                {/*  helperText={`${50 - choice.value.length} Characters Remaining`} */}
                {/*  error={choice.value.length > 50} */}
                {/* /> */}
                <FormControl
                  className={classes.menuItem}
                  variant="outlined"
                  error={choice.length > MAX_CHOICE_LENGTH}
                >
                  <InputLabel htmlFor="outlined-adornment-password">{`Choice #${
                    idx + 1
                  }`}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    value={choice}
                    onChange={(event) => {
                      const oldChoices = [...campaignChoices];
                      oldChoices[idx] = event.target.value;
                      setCampaignChoices(oldChoices);
                    }}
                    endAdornment={
                      <>
                        <InputAdornment position="end">
                          {campaignType === 'TRIVIA' && (
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={correctChoice === idx}
                                  onChange={() => setCorrectChoice(idx)}
                                  color="primary"
                                />
                              }
                              label="Is Correct?"
                              labelPlacement="end"
                            />
                          )}
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              const oldChoices = [...campaignChoices];
                              oldChoices.splice(idx, 1);
                              if (correctChoice >= oldChoices.length) {
                                setCorrectChoice(correctChoice - 1);
                              }
                              setCampaignChoices(oldChoices);
                            }}
                            disabled={campaignChoices.length <= 2}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>
                      </>
                    }
                    labelWidth={70}
                  />
                  <FormHelperText id="my-helper-text">{`${
                    MAX_CHOICE_LENGTH - choice.length
                  } Characters Remaining`}</FormHelperText>
                </FormControl>
              </Container>
            ))}
            <Container
              className={classes.menuRowRoot}
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              <Button
                style={{
                  margin: '8px',
                  marginTop: '16px',
                  marginBottom: '16px',
                  display: 'flex',
                  flex: 1,
                }}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  const oldCampaignChoices = [...campaignChoices];
                  oldCampaignChoices.push('');
                  setCampaignChoices(oldCampaignChoices);
                }}
              >
                Add Choice
              </Button>
            </Container>
            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Container>
        )}
        {/* Step 4 */}
        {activeStep === 3 && (
          <Container className={classes.menuContentRoot}>
            <Container className={classes.menuRowRoot}>
              <TextField
                label="Title"
                className={classes.menuItem}
                variant="outlined"
                value={campaignTitle}
                onChange={(event) => {
                  setCampaignTitle(event.target.value);
                }}
                helperText={`${MAX_TITLE_LENGTH - campaignTitle.length} Characters Remaining`}
                error={campaignTitle.length > MAX_TITLE_LENGTH}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Container>
        )}
        {/* Step 5 */}
        {activeStep === 4 && (
          <Container className={classes.menuContentRoot}>
            <blockquote
              style={{
                margin: '24px 0',
                padding: '4px 24px',
                borderLeft: '5px solid #678B46',
                backgroundColor: 'rgba(100,229,100,0.2)',
              }}
            >
              <p>
                <strong>Advanced Settings</strong>. Here you can optionally customize advanced
                Campaign settings, such as who is presented with the Offer and how many times people
                should be allowed to vote.
              </p>
            </blockquote>
            <Container className={classes.menuRowRoot}>
              <Autocomplete
                className={classes.menuItem}
                options={['SHOW_ALL', 'SHOW_CORRECT']}
                value={presentOfferPolicy}
                onChange={(event, newValue) => {
                  setPresentOfferPolicy(newValue);
                }}
                inputValue={presentOfferPolicyInputValue}
                onInputChange={(event, newInputValue) => {
                  setPresentOfferPolicyInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Present Offer Policy" variant="outlined" />
                )}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <Autocomplete
                className={classes.menuItem}
                options={['CHECK_COOKIES', 'NO_CHECK']}
                // options={['CHECK_COOKIES', 'CHECK_IP_AND_COOKIES', 'NO_CHECK']}
                value={entryRestrictionPolicy}
                onChange={(event, newValue) => {
                  setEntryRestrictionPolicy(newValue);
                }}
                inputValue={entryRestrictionPolicyInputValue}
                onInputChange={(event, newInputValue) => {
                  setEntryRestrictionPolicyInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Entry Restriction Policy"
                    variant="outlined"
                  />
                )}
              />
            </Container>

            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Container>
        )}
        {/* Step 6 */}
        {activeStep === 5 && (
          <Container className={classes.menuContentRoot}>
            <blockquote
              style={{
                margin: '24px 0',
                padding: '4px 24px',
                borderLeft: '5px solid #678B46',
                backgroundColor: 'rgba(100,229,100,0.2)',
              }}
            >
              <p>
                <strong>Set Schedule</strong>. Here you can specify the dates that the Campaign
                should be active for. By default the Campaign will start now and run for a week, but
                this behavior can be customized.
              </p>
            </blockquote>
            <Container className={classes.menuRowRoot}>
              <FormGroup className={classes.menuRowRoot} style={{ flex: 1 }} row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={startNow}
                      onChange={handleToggleStartNow}
                      name="start-now"
                      color="primary"
                    />
                  }
                  label="Start Now"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={noEndDate}
                      onChange={handleToggleNoEndDate}
                      name="no-end-date"
                      color="primary"
                    />
                  }
                  label="No End Date"
                />
              </FormGroup>
            </Container>
            <Container className={classes.menuRowRoot}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    className={classes.menuItem}
                    disableToolbar
                    disabled={startNow}
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Campaign Start Date"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    className={classes.menuItem}
                    disabled={noEndDate}
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Campaign End Date"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Container>
            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Container>
        )}
        {/* Step 7 */}
        {activeStep === 6 && (
          <Container className={classes.menuContentRoot}>
            <Container className={classes.menuRowRoot}>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className={classes.menuButton}
                color="primary"
                variant="contained"
                onClick={() => {
                  const body = {
                    // Additional Fields
                    choices: campaignChoices,

                    // Required Fields
                    campaign_type: campaignType,
                    prompt: campaignPrompt,

                    // Optional Fields
                    status: startNow ? 'ACTIVE' : 'PENDING',
                    present_offer_policy: presentOfferPolicy,
                    // present_sweepstakes_policy
                    entry_restriction_policy: entryRestrictionPolicy,
                    // entry_restriction_period_minutes
                  };
                  if (campaignTitle) {
                    body.title = campaignTitle;
                  }
                  if (offerValue) {
                    body.offer_id = offerValue.id;
                  }
                  if (sweepstakesValue) {
                    body.sweepstakes_id = sweepstakesValue.id;
                  }
                  if (campaignType === 'TRIVIA') {
                    body.correct_choice_id = correctChoice;
                  }
                  if (!setNoEndDate) {
                    body.end_timestamp = selectedEndDate;
                  }
                  if (featuredImage) {
                    body.featured_image_url = featuredImage.url;
                  }
                  createCampaign(body);
                  handleReset();
                  setDialogOpen(false);
                }}
              >
                Create Campaign
              </Button>
            </Container>
          </Container>
        )}
      </Container>
    </Dialog>
  );
}

NewCampaignDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  createCampaign: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default NewCampaignDialog;

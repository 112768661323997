import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Misc Imports
import { useSnackbar } from 'notistack';
import { BrowserView, MobileView } from 'react-device-detect';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCampaigns,
  createCampaign,
  fetchCampaigns,
  selectAllCampaigns,
} from './campaignsSlice';

// Local Components
import SubHeader from '../../components/SubHeader';
import DataTable from '../../components/DataTable';
import Headline from '../../components/Headline';
import NewCampaignDialog from './NewCampaignDialog';
import ShareDialog from '../../components/ShareDialog';

// Config
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  r_root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  r_content: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
  },
  r_headline: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

function CampaignDashboard(props) {
  const { organization, token } = props;
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { loginWithRedirect } = useAuth0();

  const dispatch = useDispatch();

  const campaigns = useSelector(selectAllCampaigns);
  const totalCampaignCount = useSelector((state) => state.campaigns.total_count);
  const totalActiveCampaignCount = useSelector((state) => state.campaigns.total_active_count);
  const totalPendingCampaignCount = useSelector((state) => state.campaigns.total_pending_count);
  const totalCompletedCampaignCount = useSelector((state) => state.campaigns.total_completed_count);
  const rowsPerPage = useSelector((state) => state.settings.rowsPerPage);

  const status = useSelector((state) => state.campaigns.status);

  const [pendingCreation, setPendingCreation] = useState(false);

  const loading =
    status === 'idle' || status === 'loading' || status === 'failed' || pendingCreation;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const headCells = [
    { id: 'id', type: 'number', disablePadding: false, label: 'ID' },
    { id: 'title', type: 'string', disablePadding: false, label: 'Title' },
    {
      id: 'prompt',
      type: 'string',
      disablePadding: false,
      label: 'Prompt',
    },
    {
      id: 'campaign_type',
      type: 'string',
      disablePadding: false,
      label: 'Type',
    },
    { id: 'status', type: 'string', disablePadding: false, label: 'Status' },
    {
      id: 'start_timestamp',
      type: 'date',
      disablePadding: false,
      label: 'Start Date',
    },
    { id: 'end_timestamp', type: 'date', disablePadding: false, label: 'End Date' },
    { id: 'space', disablePadding: false },
  ];

  const mobileHeadCells = [
    { id: 'id', type: 'number', disablePadding: false, label: 'ID' },
    { id: 'title', type: 'string', disablePadding: false, label: 'Title' },
    {
      id: 'campaign_type',
      type: 'string',
      disablePadding: false,
      label: 'Type',
    },
    { id: 'status', type: 'string', disablePadding: false, label: 'Status' },
    { id: 'space', disablePadding: false },
  ];

  const dense = false;

  const handleCreateCampaign = (
    body,
    page = 1,
    pageSize = rowsPerPage,
    sort = 'desc',
    column = 'id',
  ) => {
    setPendingCreation(true);
    try {
      dispatch(createCampaign({ token, body, enqueueSnackbar, page, pageSize, sort, column }));
      setPendingCreation(false);
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  const handleDeleteCampaigns = (ids) => {
    try {
      dispatch(deleteCampaigns({ token, ids, enqueueSnackbar }));
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  const handleLoadCampaigns = useCallback(
    (page = 1, pageSize = rowsPerPage, sort = 'desc', column = 'id') => {
      try {
        dispatch(fetchCampaigns({ token, enqueueSnackbar, page, pageSize, sort, column }));
      } catch (e) {
        enqueueSnackbar(`Error: ${e}`, {
          variant: 'error',
          persist: true,
        });
        loginWithRedirect();
      }
    },
    [dispatch, enqueueSnackbar, loginWithRedirect, rowsPerPage, token],
  );

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };

  useEffect(() => {
    if (token !== null) {
      handleLoadCampaigns();
    }
  }, [handleLoadCampaigns, token]);

  return (
    <Box className={classes.root}>
      <NewCampaignDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        createCampaign={handleCreateCampaign}
        token={token}
      />
      <ShareDialog
        url={selectedCampaign ? `${config.pages.URL}/${selectedCampaign.id}` : ''}
        dialogOpen={shareDialogOpen}
        onClose={handleShareDialogClose}
      />
      <Box className={classes.r_content}>
        <SubHeader
          fabTitle="New Campaign"
          setDialogOpen={setDialogOpen}
          organization={organization}
        />
        <Box className={classes.r_headline}>
          <Headline
            title="Active Campaigns"
            value={totalActiveCampaignCount}
            loading={totalActiveCampaignCount == null}
          />
          <Headline
            title="Pending Campaigns"
            value={totalPendingCampaignCount}
            loading={totalPendingCampaignCount == null}
          />
          <Headline
            title="Past Campaigns"
            value={totalCompletedCampaignCount}
            loading={totalCompletedCampaignCount == null}
          />
        </Box>
        <BrowserView>
          <DataTable
            title="All Campaigns"
            loading={loading}
            dense={dense}
            rows={campaigns}
            totalCount={totalCampaignCount}
            headCells={headCells}
            deleteRows={handleDeleteCampaigns}
            loadRows={handleLoadCampaigns}
            previewUrlPrefix="/campaigns/"
            defaultSortDirection="desc"
            shareHandler={(row) => {
              setSelectedCampaign(row);
              setShareDialogOpen(!shareDialogOpen);
            }}
          />
        </BrowserView>
        <MobileView>
          <DataTable
            title="All Campaigns"
            loading={loading}
            dense={dense}
            rows={campaigns}
            totalCount={totalCampaignCount}
            headCells={mobileHeadCells}
            deleteRows={handleDeleteCampaigns}
            loadRows={handleLoadCampaigns}
            previewUrlPrefix="/campaigns/"
            defaultSortDirection="desc"
            shareHandler={(row) => {
              setSelectedCampaign(row);
              setShareDialogOpen(!shareDialogOpen);
            }}
          />
        </MobileView>
      </Box>
    </Box>
  );
}

CampaignDashboard.propTypes = {
  organization: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default CampaignDashboard;

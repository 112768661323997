import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

// Misc Imports
import Masonry from 'react-masonry-css';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// Material UI - Lab
import Skeleton from '@material-ui/lab/Skeleton';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { fetchSponsorDetails, resetCurrentSponsor } from './sponsorsSlice';

// Local Components
import DetailCard from '../../components/DetailCard';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flex: 1,
    // flexDirection: 'column',
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  r_tabs: {
    margin: theme.spacing(1),
    display: 'flex',
    flexGrow: 1,
    // padding: theme.spacing(1),
    // flexDirection: 'column',
    // justifyContent: 'flex-start',
  },
  r_tab_panel: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  r_title: {
    margin: theme.spacing(1),
    marginBottom: 0,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  r_breadcrumb: {
    paddingBottom: theme.spacing(1),
  },
  r_breadcrumb_link: {
    textDecoration: 'none',
  },
  r_content: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    flexGrow: 1,
  },
  r_grid: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
  },
  r_grid_column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  r_grid_column_loading: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
  },
}));

const breakpointsTwoCards = {
  default: 2,
  800: 1,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      style={{ display: 'flex' }}
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={classes.r_tab_panel}>{children}</Box>}
    </div>
  );
}

function ShowSponsor(props) {
  const { token } = props;
  const classes = useStyles();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const status = useSelector((state) => state.sponsors.status);
  const sponsor = useSelector((state) => state.sponsors.current_sponsor);

  const loading = status === 'idle' || status === 'loading' || sponsor === null;

  const [value, setValue] = React.useState('0');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(resetCurrentSponsor());
    if (id !== null && token !== null) {
      dispatch(fetchSponsorDetails({ token, enqueueSnackbar, id }));
    }
  }, [dispatch, id, token, enqueueSnackbar]);

  return (
    <Box className={classes.root}>
      <Box className={classes.r_title}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.r_breadcrumb}>
          <Link className={classes.r_breadcrumb_link} to="/sponsors">
            <Typography color="textSecondary">Sponsors</Typography>
          </Link>
          <Typography color="textPrimary">Sponsor #{id} Details</Typography>
        </Breadcrumbs>
        <Typography variant="h5" component="h2" className={classes.title}>
          {sponsor ? sponsor.title : <Skeleton variant="text" className={classes.title} />}
        </Typography>
      </Box>
      <div className={classes.r_tabs}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab value="0" label="Details" />
        </Tabs>
        <Divider />
      </div>
      <Box className={classes.r_content}>
        <TabPanel value={value} index="0">
          <Masonry
            breakpointCols={breakpointsTwoCards}
            className={classes.r_grid}
            columnClassName={loading ? classes.r_grid_column_loading : classes.r_grid_column}
          >
            <DetailCard title="Sponsor Info" loading={loading} object={sponsor} />
          </Masonry>
        </TabPanel>
      </Box>
    </Box>
  );
}

ShowSponsor.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ShowSponsor;

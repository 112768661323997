/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Misc Imports
import { SnackbarProvider } from 'notistack';

// Local
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import theme from './theme';
import './index.css';
import App from './App';
import Auth0ProviderWithHistory from './auth0-provider-with-history';
import { store, persistor } from './app/store';

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            ref={notistackRef}
            action={(key) => (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onClickDismiss(key)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          >
            <BrowserRouter>
              <Auth0ProviderWithHistory>
                <App />
              </Auth0ProviderWithHistory>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

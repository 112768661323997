/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config';

const initialState = {
  status: 'idle',
  error: null,
  total_count: null,
  search_results: [],
  sponsors: [],
  current_sponsor: null,
};

export const fetchSponsors = createAsyncThunk(
  'sponsors/fetchSponsors',
  async ({ token, enqueueSnackbar, page, pageSize, sort, column }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${config.api.URL}/admin/sponsors?${new URLSearchParams({
          page,
          page_size: pageSize,
          sort,
          column,
        })}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const json = await response.json();
      const { data, total_count } = json;

      if (response.ok) {
        return {
          data,
          total_count,
        };
      }

      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const fetchSponsorDetails = createAsyncThunk(
  'sponsors/fetchSponsorDetails',
  async ({ token, enqueueSnackbar, id }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${config.api.URL}/admin/sponsors/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();
      const { data } = json;

      if (response.ok) {
        return {
          data,
        };
      }
      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const searchSponsors = createAsyncThunk(
  'sponsors/searchSponsors',
  async ({ token, enqueueSnackbar, q }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${config.api.URL}/admin/sponsors?${new URLSearchParams({ q })}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const json = await response.json();
      const { data } = json;

      if (response.ok) {
        return { data };
      }

      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const createSponsor = createAsyncThunk(
  'sponsors/createSponsor',
  async (
    { token, body, enqueueSnackbar, page, pageSize, sort, column },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await fetch(`${config.api.URL}/admin/sponsors`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();

      if (response.ok) {
        enqueueSnackbar('Sponsor Created', { variant: 'success' });
        dispatch(fetchSponsors({ token, enqueueSnackbar, page, pageSize, sort, column }));
        return;
      }

      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
    }
  },
);

export const deleteSponsors = createAsyncThunk(
  'sponsors/deleteSponsors',
  async ({ token, ids, enqueueSnackbar }, { dispatch }) => {
    try {
      const statements = [];

      ids.forEach((id) => {
        statements.push(
          fetch(`${config.api.URL}/admin/sponsors/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }),
        );
      });

      await Promise.all(statements).then((responses) => {
        responses.forEach((res) => {
          if (!res.ok) {
            enqueueSnackbar(`Error: ${JSON.stringify(res.json().error.message)}`, {
              variant: 'error',
              persist: true,
            });
          }
        });
        if (statements.length === 1) {
          enqueueSnackbar(`Sponsor Deleted`, { variant: 'success' });
        } else {
          enqueueSnackbar(`${statements.length} Sponsors Deleted`, { variant: 'success' });
        }
      });

      return { ids };
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
    }
  },
);

export const sponsorsSlice = createSlice({
  name: 'sponsors',
  initialState,
  reducers: {
    resetSearchResults(state) {
      state.search_results = [];
    },
    resetCurrentSponsor(state) {
      state.current_sponsor = null;
    },
  },
  extraReducers: {
    // fetchSponsors
    [fetchSponsors.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSponsors.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.sponsors = action.payload.data;
      state.total_count = action.payload.total_count;
    },
    [fetchSponsors.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // fetchSponsorDetails
    [fetchSponsorDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSponsorDetails.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.current_sponsor = action.payload.data;
    },
    [fetchSponsorDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // searchSponsors
    [searchSponsors.pending]: (state) => {
      state.status = 'loading';
      state.search_results = [];
    },
    [searchSponsors.fulfilled]: (state, action) => {
      if (state.status === 'loading') {
        state.search_results = action.payload.data;
      }
      state.status = 'succeeded';
    },
    [searchSponsors.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // createSponsor
    [createSponsor.pending]: (state) => {
      state.status = 'loading';
    },
    [createSponsor.fulfilled]: () => {
      // state.status = 'succeeded';
    },
    [createSponsor.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // deleteSponsors
    [deleteSponsors.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteSponsors.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      console.log(action.payload.ids);
      state.sponsors = state.sponsors.filter((sponsor) => !action.payload.ids.includes(sponsor.id));
    },
    [deleteSponsors.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const { resetSearchResults, resetCurrentSponsor } = sponsorsSlice.actions;
export default sponsorsSlice.reducer;

export const selectAllSponsors = (state) => state.sponsors.sponsors;

export const selectSponsorsSearchResults = (state) => state.sponsors.search_results;

export const selectSponsorById = (state, sponsorId) =>
  state.sponsors.sponsors.find((x) => x.id.toString() === sponsorId);

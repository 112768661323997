import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Misc Imports
import { useSnackbar } from 'notistack';
import { BrowserView, MobileView } from 'react-device-detect';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllSweepstakes,
  createSweepstakes,
  deleteSweepstakes,
  fetchSweepstakes,
} from './sweepstakesSlice';

// Local Components
import SubHeader from '../../components/SubHeader';
import DataTable from '../../components/DataTable';
import NewSweepstakesDialog from './NewSweepstakesDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  r_root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    justifyContent: 'flex-start',
  },
  r_content: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
  },
  r_headline: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

function SweepstakesDashboard(props) {
  const { organization, token } = props;
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { loginWithRedirect } = useAuth0();

  const dispatch = useDispatch();

  const sweepstakes = useSelector(selectAllSweepstakes);
  const totalSweepstakesCount = useSelector((state) => state.sweepstakes.total_count);
  const rowsPerPage = useSelector((state) => state.settings.rowsPerPage);

  const status = useSelector((state) => state.sweepstakes.status);

  const [pendingCreation, setPendingCreation] = useState(false);

  const loading =
    status === 'idle' || status === 'loading' || status === 'failed' || pendingCreation;

  const [dialogOpen, setDialogOpen] = useState(false);

  const headCells = [
    { id: 'id', type: 'number', disablePadding: true, label: 'ID' },
    { id: 'title', type: 'string', disablePadding: false, label: 'Title' },
    {
      parent: 'sponsor',
      id: 'company_name',
      type: 'string',
      disablePadding: false,
      label: 'Sponsor',
    },
    { id: 'space', disablePadding: false },
  ];

  const mobileHeadCells = [
    { id: 'id', type: 'number', disablePadding: true, label: 'ID' },
    { id: 'title', type: 'string', disablePadding: false, label: 'Title' },
    {
      parent: 'sponsor',
      id: 'company_name',
      type: 'string',
      disablePadding: false,
      label: 'Sponsor',
    },
    { id: 'space', disablePadding: false },
  ];

  const dense = false;

  const handleCreateSweepstakes = (
    body,
    page = 1,
    pageSize = rowsPerPage,
    sort = 'desc',
    column = 'id',
  ) => {
    setPendingCreation(true);
    try {
      dispatch(createSweepstakes({ token, body, enqueueSnackbar, page, pageSize, sort, column }));
      setPendingCreation(false);
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  const handleDeleteSweepstakes = (ids) => {
    try {
      dispatch(deleteSweepstakes({ token, ids, enqueueSnackbar }));
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  const handleLoadSweepstakes = useCallback(
    (page = 1, pageSize = rowsPerPage, sort = 'desc', column = 'id') => {
      try {
        dispatch(fetchSweepstakes({ token, enqueueSnackbar, page, pageSize, sort, column }));
      } catch (e) {
        enqueueSnackbar(`Error: ${e}`, {
          variant: 'error',
          persist: true,
        });
        loginWithRedirect();
      }
    },
    [dispatch, enqueueSnackbar, loginWithRedirect, rowsPerPage, token],
  );

  useEffect(() => {
    if (token !== null) {
      handleLoadSweepstakes();
    }
  }, [handleLoadSweepstakes, token]);

  return (
    <Box className={classes.root}>
      <NewSweepstakesDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        createSweepstakes={handleCreateSweepstakes}
        token={token}
      />
      <Box className={classes.r_content}>
        <SubHeader
          fabTitle="New Sweepstakes"
          setDialogOpen={setDialogOpen}
          organization={organization}
        />
        {/* <Box className={classes.r_headline}> */}
        {/*  <Headline */}
        {/*    title="Active Sweepstakes" */}
        {/*    value={sweepstakes.filter((x) => x.status === 'ACTIVE').length} */}
        {/*    loading={loading} */}
        {/*  /> */}
        {/*  <Headline */}
        {/*    title="Inactive Sweepstakes" */}
        {/*    value={sweepstakes.filter((x) => x.status === 'INACTIVE').length} */}
        {/*    loading={loading} */}
        {/*  /> */}
        {/* </Box> */}
        <BrowserView>
          <DataTable
            title="All Sweepstakes"
            loading={loading}
            dense={dense}
            rows={sweepstakes}
            totalCount={totalSweepstakesCount}
            headCells={headCells}
            deleteRows={handleDeleteSweepstakes}
            loadRows={handleLoadSweepstakes}
            previewUrlPrefix="/sweepstakes/"
            defaultSortDirection="desc"
          />
        </BrowserView>
        <MobileView>
          <DataTable
            title="All Sweepstakes"
            loading={loading}
            dense={dense}
            rows={sweepstakes}
            totalCount={totalSweepstakesCount}
            headCells={mobileHeadCells}
            deleteRows={handleDeleteSweepstakes}
            loadRows={handleLoadSweepstakes}
            previewUrlPrefix="/sweepstakes/"
            defaultSortDirection="desc"
          />
        </MobileView>
      </Box>
    </Box>
  );
}

SweepstakesDashboard.propTypes = {
  organization: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default SweepstakesDashboard;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI - Core
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';

// Material UI - Icons
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

const _ = require('lodash');

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: theme.palette.tertinary.main,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    minWidth: 82,
    backgroundColor: theme.palette.tertinary.main,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(10) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  list: {
    flexGrow: 1,
    backgroundColor: theme.palette.tertinary.main,
    color: theme.palette.primary.contrastText,
  },
  icon: {
    color: theme.palette.primary.contrastText,
    marginLeft: 12.5,
  },
  logo: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
    marginBottom: 8,
    alignSelf: 'flex-start',
    display: 'block',
    borderRadius: 8,
    height: 50,
    width: 50,
  },
  list_item_root: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    '&.Mui-selected': {
      backgroundColor: 'black',
      '&:hover': {
        backgroundColor: 'black',
      },
    },
    '&:hover': {
      backgroundColor: '#222',
    },
  },
}));

function CustomDrawer(props) {
  const { open } = props;
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const shouldOpen = open && matches;

  const location = useLocation();

  const icons = [<GroupIcon />, <LocalOfferIcon />, <BusinessIcon />, <ConfirmationNumberIcon />];
  const pages = ['Campaigns', 'Offers', 'Sponsors', 'Sweepstakes'];
  const links = ['/campaigns', '/offers', '/sponsors', '/sweepstakes'];

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: shouldOpen,
          [classes.drawerClose]: !shouldOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: shouldOpen,
            [classes.drawerClose]: !shouldOpen,
          }),
        }}
      >
        <img
          className={classes.logo}
          src={require('../assets/simple-logo-dark-512.png')}
          alt="SpeaQ Logo"
        />
        <List className={classes.list}>
          {pages.map((text, index) => (
            <ListItem
              button
              key={text}
              component={Link}
              to={links[index]}
              selected={_.includes(location.pathname.split('/'), _.trim(links[index], '/'))}
              classes={{ root: classes.list_item_root }}
            >
              <ListItemIcon className={classes.icon}>{icons[index]}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
}

CustomDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default CustomDrawer;

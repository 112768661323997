/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config';

const initialState = {
  status: 'idle',
  error: null,
  organizations: null,
  selectedOrganizationId: null,
  user: null,
};

export const fetchSelfOrganizations = createAsyncThunk(
  'auth/fetchSelfOrganizations',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${config.api.URL}/admin/self/organizations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const json = await response.json();
        return json.data;
      }
      return rejectWithValue(response);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateSelectedOrganizationId: {
      reducer(state, action) {
        state.selectedOrganizationId = action.payload;
      },
    },
  },
  extraReducers: {
    // fetchSelfOrganizations
    [fetchSelfOrganizations.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSelfOrganizations.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.organizations = action.payload;
      if (
        'selectedOrganizationId' in state &&
        !state.selectedOrganizationId &&
        'payload' in action &&
        typeof action.payload === 'object' &&
        action.payload.length >= 0 &&
        action.payload[0] &&
        'id' in action.payload[0]
      ) {
        state.selectedOrganizationId = action.payload[0].id;
      }
    },
    [fetchSelfOrganizations.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const { updateSelectedOrganizationId } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentOrganization = (state) =>
  state.auth.organizations
    ? state.auth.organizations.find((org) => org.id === state.auth.selectedOrganizationId)
    : undefined;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { round } from 'lodash';
import config from '../../config';

const initialState = {
  status: 'idle',
  votesStatus: 'idle',
  error: null,
  total_count: null,
  total_active_count: null,
  total_pending_count: null,
  total_completed_count: null,
  current_campaign: null,
  current_campaign_votes: [],
  total_campaign_votes_count: null,
  campaigns: [],
};

export const fetchCampaigns = createAsyncThunk(
  'campaigns/fetchCampaigns',
  async ({ token, enqueueSnackbar, page, pageSize, sort, column }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${config.api.URL}/admin/campaigns?${new URLSearchParams({
          page,
          page_size: pageSize,
          sort,
          column,
        })}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const json = await response.json();
      const {
        data,
        total_count,
        total_active_count,
        total_pending_count,
        total_completed_count,
      } = json;

      if (response.ok) {
        return {
          data,
          total_count,
          total_active_count,
          total_pending_count,
          total_completed_count,
        };
      }
      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const fetchCampaignDetails = createAsyncThunk(
  'campaigns/fetchCampaignDetails',
  async ({ token, enqueueSnackbar, id }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${config.api.URL}/admin/campaigns/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();
      const { data } = json;

      if (response.ok) {
        // Update recent votes to add Choice text
        if ('choices' in data && 'recent_votes' in data) {
          const choiceIDs = data.choices.map((x) => x.id);
          data.recent_votes = data.recent_votes.map((vote) => {
            const newVote = { ...vote };
            if (choiceIDs.includes(newVote.choice_id)) {
              newVote.choice = data.choices.filter((x) => x.id === newVote.choice_id)[0].text;
            }
            return newVote;
          });
        }

        // Update vote summary format
        if ('choices' in data && 'vote_summary' in data) {
          const choiceIDs = data.choices.map((x) => x.id);
          const { total_count } = data.vote_summary;

          const newResults = [];

          for (const [k, v] of Object.entries(data.vote_summary.results)) {
            const newResult = {};
            if (choiceIDs.includes(parseInt(k, 10))) {
              newResult.choice = data.choices.filter((x) => x.id === parseInt(k, 10))[0].text;
              newResult.id = k;
            }
            newResult.percentage = `${round((v / total_count) * 100.0, 1)}%`;
            newResult.count = v;
            newResults.push(newResult);
          }

          data.vote_summary.results = newResults;
        }

        // Update vote summary format
        if ('vote_summary' in data && 'sources' in data.vote_summary) {
          const { total_count } = data.vote_summary;

          data.vote_summary.sources = data.vote_summary.sources.map(function (x) {
            const o = { ...x };
            o.percentage = `${round((o.value / total_count) * 100.0, 1)}%`;
            return o;
          });

          data.vote_summary.save_methods = data.vote_summary.save_methods.map(function (x) {
            const o = { ...x };
            o.percentage = `${round((o.value / total_count) * 100.0, 1)}%`;
            return o;
          });
        }

        return data;
      }
      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const fetchAllCampaignVotes = createAsyncThunk(
  'campaigns/fetchAllCampaignVotes',
  async (
    { token, id, enqueueSnackbar, page, pageSize, sort, column },
    { rejectWithValue, getState },
  ) => {
    try {
      const response = await fetch(
        `${config.api.URL}/admin/campaigns/${id}/votes?${new URLSearchParams({
          page,
          page_size: pageSize,
          sort,
          column,
        })}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const json = await response.json();
      let { data, total_count } = json;

      if (response.ok) {
        const { campaigns } = getState();

        const existingCampaign = campaigns.current_campaign;

        // Update votes to add Choice text
        if (existingCampaign && 'choices' in existingCampaign) {
          const choiceIDs = existingCampaign.choices.map((x) => x.id);

          data = data.map((vote) => {
            const newVote = { ...vote };
            if (choiceIDs.includes(newVote.id)) {
              newVote.choice = existingCampaign.choices.filter((x) => x.id === newVote.id)[0].text;
            }
            return newVote;
          });
        }

        return { data, total_count };
      }
      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const createCampaign = createAsyncThunk(
  'campaigns/createCampaign',
  async (
    { token, body, enqueueSnackbar, page, pageSize, sort, column },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await fetch(`${config.api.URL}/admin/campaigns`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();

      if (response.ok) {
        enqueueSnackbar('Campaign Created', { variant: 'success' });
        dispatch(fetchCampaigns({ token, enqueueSnackbar, page, pageSize, sort, column }));
        return;
      }

      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
    }
  },
);

export const deleteCampaigns = createAsyncThunk(
  'campaigns/deleteCampaigns',
  async ({ token, ids, enqueueSnackbar }, { dispatch }) => {
    try {
      const statements = [];

      ids.forEach((id) => {
        statements.push(
          fetch(`${config.api.URL}/admin/campaigns/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }),
        );
      });

      await Promise.all(statements).then((responses) => {
        responses.forEach((res) => {
          if (!res.ok) {
            enqueueSnackbar(`Error: ${JSON.stringify(res.json().error.message)}`, {
              variant: 'error',
              persist: true,
            });
          }
        });
        if (statements.length === 1) {
          enqueueSnackbar(`Campaign Deleted`, { variant: 'success' });
        } else {
          enqueueSnackbar(`${statements.length} Campaigns Deleted`, { variant: 'success' });
        }
      });

      return { ids };
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
    }
  },
);

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    resetCurrentCampaign(state) {
      state.current_campaign = null;
    },
  },
  extraReducers: {
    // fetchCampaigns
    [fetchCampaigns.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCampaigns.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.campaigns = action.payload.data;
      state.total_count = action.payload.total_count;
      state.total_active_count = action.payload.total_active_count;
      state.total_pending_count = action.payload.total_pending_count;
      state.total_completed_count = action.payload.total_completed_count;
    },
    [fetchCampaigns.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // fetchCampaignDetails
    [fetchCampaignDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCampaignDetails.fulfilled]: (state, action) => {
      // eslint-disable-next-line camelcase
      // const { id, vote_summary, recent_votes } = action.payload;
      // const existingCampaign = state.campaigns.find((campaign) => campaign.id === id);
      //
      // if (existingCampaign) {
      //   // eslint-disable-next-line camelcase
      //   existingCampaign.vote_summary = vote_summary;
      //   // eslint-disable-next-line camelcase
      //   existingCampaign.recent_votes = recent_votes;
      // } else {
      //   state.campaigns.push(action.payload);
      // }

      state.current_campaign = action.payload;
      state.status = 'succeeded';
    },
    [fetchCampaignDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // fetchAllCampaignVotes
    [fetchAllCampaignVotes.pending]: (state) => {
      state.votesStatus = 'loading';
    },
    [fetchAllCampaignVotes.fulfilled]: (state, action) => {
      const { data, total_count } = action.payload;

      state.current_campaign_votes = data;
      state.total_campaign_votes_count = total_count;
      state.votesStatus = 'succeeded';
    },
    [fetchAllCampaignVotes.rejected]: (state, action) => {
      state.votesStatus = 'failed';
      state.error = action.error.message;
    },
    // createCampaign
    [createCampaign.pending]: (state) => {
      state.status = 'loading';
    },
    [createCampaign.fulfilled]: () => {
      // state.status = 'succeeded';
    },
    [createCampaign.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // deleteCampaigns
    [deleteCampaigns.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteCampaigns.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      console.log(action.payload.ids);
      state.campaigns = state.campaigns.filter(
        (campaign) => !action.payload.ids.includes(campaign.id),
      );
    },
    [deleteCampaigns.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const { resetCurrentCampaign } = campaignsSlice.actions;
export default campaignsSlice.reducer;

export const selectAllCampaigns = (state) => state.campaigns.campaigns;

// TODO: Remove below
export const selectCampaignById = (state, campaignId) =>
  state.campaigns.campaigns.find((x) => x.id.toString() === campaignId.toString());

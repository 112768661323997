import React from 'react';
import PropTypes from 'prop-types';

// Material UI - Core
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

function ConfirmExit(props) {
  const { confirmExitOpen, handleConfirmExitCancel, handleConfirmExitConfirm } = props;

  return (
    <Dialog
      open={confirmExitOpen}
      onClose={handleConfirmExitCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm Exit</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to exit? All unsaved changes will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmExitCancel} color="primary">
          No
        </Button>
        <Button onClick={handleConfirmExitConfirm} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmExit.propTypes = {
  confirmExitOpen: PropTypes.bool.isRequired,
  handleConfirmExitCancel: PropTypes.func.isRequired,
  handleConfirmExitConfirm: PropTypes.func.isRequired,
};

export default ConfirmExit;

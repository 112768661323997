/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config';

const initialState = {
  status: 'idle',
  error: null,
  total_count: null,
  search_results: [],
  sweepstakes: [],
  current_sweepstakes: null,
};

export const fetchSweepstakes = createAsyncThunk(
  'sweepstakes/fetchSweepstakes',
  async ({ token, enqueueSnackbar, page, pageSize, sort, column }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${config.api.URL}/admin/sweepstakes?${new URLSearchParams({
          page,
          page_size: pageSize,
          sort,
          column,
        })}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const json = await response.json();
      const { data, total_count } = json;

      if (response.ok) {
        return {
          data,
          total_count,
        };
      }

      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const fetchSweepstakesDetails = createAsyncThunk(
  'sweepstakes/fetchSweepstakesDetails',
  async ({ token, enqueueSnackbar, id }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${config.api.URL}/admin/sweepstakes/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();
      const { data } = json;

      if (response.ok) {
        return {
          data,
        };
      }
      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const searchSweepstakes = createAsyncThunk(
  'sweepstakes/searchSweepstakes',
  async ({ token, enqueueSnackbar, q, sponsorId }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${config.api.URL}/admin/sweepstakes?${new URLSearchParams({ q, sponsorId })}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const json = await response.json();
      const { data } = json;

      if (response.ok) {
        return { data };
      }

      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const createSweepstakes = createAsyncThunk(
  'sweepstakes/createSweepstakes',
  async (
    { token, body, enqueueSnackbar, page, pageSize, sort, column },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await fetch(`${config.api.URL}/admin/sweepstakes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();

      if (response.ok) {
        enqueueSnackbar('Sweepstakes Created', { variant: 'success' });
        dispatch(fetchSweepstakes({ token, enqueueSnackbar, page, pageSize, sort, column }));
        return;
      }

      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
    }
  },
);

export const deleteSweepstakes = createAsyncThunk(
  'sweepstakes/deleteSweepstakes',
  async ({ token, ids, enqueueSnackbar }, { dispatch }) => {
    try {
      const statements = [];

      ids.forEach((id) => {
        statements.push(
          fetch(`${config.api.URL}/admin/sweepstakes/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }),
        );
      });

      await Promise.all(statements).then((responses) => {
        responses.forEach((res) => {
          if (!res.ok) {
            enqueueSnackbar(`Error: ${JSON.stringify(res.json().error.message)}`, {
              variant: 'error',
              persist: true,
            });
          }
        });
        if (statements.length === 1) {
          enqueueSnackbar(`Sweepstakes Deleted`, { variant: 'success' });
        } else {
          enqueueSnackbar(`${statements.length} Sweepstakes Deleted`, { variant: 'success' });
        }
      });

      return { ids };
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
    }
  },
);

export const sweepstakesSlice = createSlice({
  name: 'sweepstakes',
  initialState,
  reducers: {
    resetSearchResults(state) {
      state.search_results = [];
    },
    resetCurrentSweepstakes(state) {
      state.current_sweepstakes = null;
    },
  },
  extraReducers: {
    // fetchSweepstakes
    [fetchSweepstakes.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSweepstakes.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.sweepstakes = action.payload.data;
      state.total_count = action.payload.total_count;
    },
    [fetchSweepstakes.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // fetchSweepstakesDetails
    [fetchSweepstakesDetails().pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSweepstakesDetails.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.current_sweepstakes = action.payload.data;
    },
    [fetchSweepstakesDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // searchSweepstakes
    [searchSweepstakes.pending]: (state) => {
      state.status = 'loading';
      state.search_results = [];
    },
    [searchSweepstakes.fulfilled]: (state, action) => {
      if (state.status === 'loading') {
        state.search_results = action.payload.data;
      }
      state.status = 'succeeded';
    },
    [searchSweepstakes.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // createSweepstakes
    [createSweepstakes.pending]: (state) => {
      state.status = 'loading';
    },
    [createSweepstakes.fulfilled]: () => {
      // state.status = 'succeeded';
    },
    [createSweepstakes.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // deleteSweepstakes
    [deleteSweepstakes.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteSweepstakes.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      console.log(action.payload.ids);
      state.sweepstakes = state.sweepstakes.filter(
        (sweepstakes) => !action.payload.ids.includes(sweepstakes.id),
      );
    },
    [deleteSweepstakes.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const { resetSearchResults, resetCurrentSweepstakes } = sweepstakesSlice.actions;
export default sweepstakesSlice.reducer;

export const selectAllSweepstakes = (state) => state.sweepstakes.sweepstakes;

export const selectSweepstakesSearchResults = (state) => state.sweepstakes.search_results;

export const selectSweepstakesById = (state, sweepstakesId) =>
  state.sweepstakes.sweepstakes.find((x) => x.id.toString() === sweepstakesId);

import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

// Misc Imports
import Masonry from 'react-masonry-css';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// Material UI - Lab
import Skeleton from '@material-ui/lab/Skeleton';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  fetchCampaignDetails,
  fetchAllCampaignVotes,
  resetCurrentCampaign,
} from './campaignsSlice';

// Local Components
import DetailCard from '../../components/DetailCard';
import MapCard from '../../components/MapCard';
import SimpleDataTableCard from '../../components/SimpleDataTableCard';
import Loading from '../../components/Loading';
import DataTable from '../../components/DataTable';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flex: 1,
    // flexDirection: 'column',
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  r_tabs: {
    margin: theme.spacing(1),
    display: 'flex',
    flexGrow: 1,
    // padding: theme.spacing(1),
    // flexDirection: 'column',
    // justifyContent: 'flex-start',
  },
  r_tab_panel: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  r_title: {
    margin: theme.spacing(1),
    marginBottom: 0,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  r_breadcrumb: {
    paddingBottom: theme.spacing(1),
  },
  r_breadcrumb_link: {
    textDecoration: 'none',
  },
  r_content: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    flexGrow: 1,
  },
  r_grid: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
  },
  r_grid_column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  r_grid_column_loading: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
  },
}));

const breakpointsTwoCards = {
  default: 2,
  800: 1,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.r_tab_panel}
      role="tabpanel"
      style={{ display: 'flex' }}
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function ShowCampaign(props) {
  const { token } = props;
  const classes = useStyles();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { loginWithRedirect } = useAuth0();

  const status = useSelector((state) => state.campaigns.status);
  const votesStatus = useSelector((state) => state.campaigns.votesStatus);
  const totalVotesCount = useSelector((state) => state.campaigns.total_campaign_votes_count);
  const rowsPerPage = useSelector((state) => state.settings.rowsPerPage);

  const campaign = useSelector((state) => state.campaigns.current_campaign);
  const votes = useSelector((state) => state.campaigns.current_campaign_votes);

  const loading = status === 'idle' || status === 'loading' || campaign === null;
  const votesLoading = votesStatus === 'idle' || votesStatus === 'loading' || votes === null;

  const [value, setValue] = React.useState('0');

  const recentVotesHeadCells = [
    {
      id: 'choice',
      type: 'number',
      disablePadding: false,
      label: 'Choice',
    },
    {
      id: 'contact_info',
      type: 'string',
      disablePadding: false,
      label: 'Contact Info',
    },
    {
      id: 'utm_medium',
      type: 'string',
      disablePadding: false,
      label: 'Source',
    },
    {
      id: 'created_date',
      type: 'string',
      disablePadding: false,
      label: 'Timestamp',
    },
  ];

  const voteSummaryHeadCells = [
    {
      id: 'choice',
      type: 'string',
      disablePadding: false,
      label: 'Choice',
    },
    {
      id: 'percentage',
      type: 'string',
      disablePadding: false,
      label: 'Percentage',
    },
    {
      id: 'count',
      type: 'string',
      disablePadding: false,
      label: 'Count',
    },
  ];

  const voteSummaryFooterCells = [
    {
      id: 'total_votes',
      type: 'string',
      disablePadding: false,
      label: 'Total Votes',
    },
    {
      id: 'space',
      type: 'string',
      disablePadding: false,
      label: '',
    },
    {
      id: 'total_count',
      type: 'number',
      disablePadding: false,
      label: campaign && 'vote_summary' in campaign && campaign.vote_summary.total_count,
    },
  ];

  const sourceSummaryHeadCells = [
    {
      id: 'id',
      type: 'string',
      disablePadding: false,
      label: 'UTM Medium',
    },
    {
      id: 'percentage',
      type: 'string',
      disablePadding: false,
      label: 'Percentage',
    },
    {
      id: 'value',
      type: 'string',
      disablePadding: false,
      label: 'Count',
    },
  ];

  const saveRateSummaryHeadCells = [
    {
      id: 'id',
      type: 'string',
      disablePadding: false,
      label: 'Save Method',
    },
    {
      id: 'percentage',
      type: 'string',
      disablePadding: false,
      label: 'Percentage',
    },
    {
      id: 'value',
      type: 'string',
      disablePadding: false,
      label: 'Count',
    },
  ];

  const ipAnalysisSummaryHeadCells = [
    {
      id: 'id',
      type: 'string',
      disablePadding: false,
      label: 'IP Address',
    },
    {
      id: 'value',
      type: 'string',
      disablePadding: false,
      label: 'Vote Count',
    },
  ];

  const allVotesHeadCells = [
    {
      id: 'choice',
      type: 'number',
      disablePadding: false,
      label: 'Choice',
    },
    {
      id: 'contact_info',
      type: 'string',
      disablePadding: false,
      label: 'Contact Info',
    },
    {
      id: 'utm_medium',
      type: 'string',
      disablePadding: false,
      label: 'Source',
    },
    {
      id: 'city',
      type: 'string',
      disablePadding: false,
      label: 'City',
    },
    {
      id: 'region_code',
      type: 'string',
      disablePadding: false,
      label: 'State',
    },
    {
      id: 'ip_address',
      type: 'string',
      disablePadding: false,
      label: 'IP Address',
    },
    {
      id: 'created_date',
      type: 'string',
      disablePadding: false,
      label: 'Timestamp',
    },
  ];

  const dense = false;

  const handleLoadVotes = (
    page = 1,
    pageSize = rowsPerPage,
    sort = 'desc',
    column = 'created_date',
  ) => {
    try {
      dispatch(fetchAllCampaignVotes({ token, id, enqueueSnackbar, page, pageSize, sort, column }));
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  const handleChange = (event, newValue) => {
    if (newValue === '4' && votesStatus !== 'loading' && campaign && !campaign.all_votes) {
      handleLoadVotes();
    }
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(resetCurrentCampaign());
    if (id !== null && token !== null) {
      dispatch(fetchCampaignDetails({ token, enqueueSnackbar, id }));
    }
  }, [dispatch, id, token, enqueueSnackbar]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.r_title}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.r_breadcrumb}>
          <Link className={classes.r_breadcrumb_link} to="/campaigns">
            <Typography color="textSecondary">Campaigns</Typography>
          </Link>
          <Typography color="textPrimary">Campaign #{id} Details</Typography>
        </Breadcrumbs>
        <Typography variant="h5" component="h2" className={classes.title}>
          {campaign ? campaign.prompt : <Skeleton variant="text" className={classes.title} />}
        </Typography>
      </Box>
      <div className={classes.r_tabs}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab value="0" label="Results" />
          {/* <Tab value="1" label="Settings" disabled /> */}
          <Tab value="2" label="Details" />
          {/* <Tab value="3" label="Style" disabled /> */}
          <Tab value="4" label="All Votes" />
        </Tabs>
        <Divider />
      </div>
      <Box className={classes.r_content}>
        <TabPanel value={value} index="0">
          <Masonry
            breakpointCols={breakpointsTwoCards}
            className={classes.r_grid}
            columnClassName={loading ? classes.r_grid_column_loading : classes.r_grid_column}
          >
            <SimpleDataTableCard
              title="Results"
              loading={loading}
              maxRows={10}
              dense={dense}
              headCells={voteSummaryHeadCells}
              footerCells={voteSummaryFooterCells}
              rows={
                campaign && 'vote_summary' in campaign && 'results' in campaign.vote_summary
                  ? campaign.vote_summary.results
                  : []
              }
            />
            <SimpleDataTableCard
              title="Source"
              loading={loading}
              maxRows={10}
              dense={dense}
              headCells={sourceSummaryHeadCells}
              rows={
                campaign && 'vote_summary' in campaign && 'sources' in campaign.vote_summary
                  ? campaign.vote_summary.sources
                  : []
              }
            />
            <MapCard
              data={campaign && 'vote_summary' in campaign ? campaign.vote_summary.states : []}
            />
            <SimpleDataTableCard
              title="Recent Votes"
              footerTitle="See All Votes"
              footerButtonHandler={handleChange}
              loading={loading}
              maxRows={10}
              dense={dense}
              headCells={recentVotesHeadCells}
              rows={campaign && 'recent_votes' in campaign ? campaign.recent_votes : []}
            />
            <SimpleDataTableCard
              title="Save Rate"
              loading={loading}
              maxRows={10}
              dense={dense}
              headCells={saveRateSummaryHeadCells}
              rows={
                campaign && 'vote_summary' in campaign && 'save_methods' in campaign.vote_summary
                  ? campaign.vote_summary.save_methods
                  : []
              }
            />
            <SimpleDataTableCard
              title="IP Analysis"
              loading={loading}
              maxRows={10}
              dense={dense}
              headCells={ipAnalysisSummaryHeadCells}
              rows={
                campaign && 'vote_summary' in campaign && 'ip_analysis' in campaign.vote_summary
                  ? campaign.vote_summary.ip_analysis
                  : []
              }
            />
          </Masonry>
        </TabPanel>
        <TabPanel value={value} index="1" />
        <TabPanel value={value} index="2">
          <Masonry
            breakpointCols={breakpointsTwoCards}
            className={classes.r_grid}
            columnClassName={loading ? classes.r_grid_column_loading : classes.r_grid_column}
          >
            <DetailCard title="Campaign Info" loading={loading} object={campaign} />
            {campaign && campaign.offer !== null ? (
              <DetailCard title="Offer Info" loading={loading} object={campaign.offer} />
            ) : null}
            {campaign && campaign.sweepstakes !== null ? (
              <DetailCard
                title="Sweepstakes Info"
                loading={loading}
                object={campaign.sweepstakes}
              />
            ) : null}
            {campaign && campaign.offer !== null && campaign.offer.sponsor !== null ? (
              <DetailCard title="Sponsor Info" loading={loading} object={campaign.offer.sponsor} />
            ) : null}
          </Masonry>
        </TabPanel>
        <TabPanel value={value} index="3" />
        <TabPanel value={value} index="4">
          <DataTable
            title="All Votes"
            loading={votesLoading}
            dense={dense}
            rows={votes}
            totalCount={totalVotesCount}
            headCells={allVotesHeadCells}
            loadRows={handleLoadVotes}
            defaultSortDirection="desc"
            defaultSortColumn="created_date"
            showPreviews={false}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}

ShowCampaign.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ShowCampaign;

import React from 'react';
import PropTypes from 'prop-types';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

// Material UI - Lab
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: '100%',
  },
  title: {
    fontSize: 14,
  },
}));

function Headline(props) {
  const { loading, value, title } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        {loading ? (
          <>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5" component="h2">
              <Skeleton variant="text" />
            </Typography>
          </>
        ) : (
          <>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5" component="h2">
              {value}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
}

Headline.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Headline;

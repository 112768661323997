import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Misc Imports
import { useSnackbar } from 'notistack';
import { BrowserView, MobileView } from 'react-device-detect';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { selectAllSponsors, createSponsor, deleteSponsors, fetchSponsors } from './sponsorsSlice';

// Local Components
import SubHeader from '../../components/SubHeader';
import DataTable from '../../components/DataTable';
import NewSponsorDialog from './NewSponsorDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  r_root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    justifyContent: 'flex-start',
  },
  r_content: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
  },
  r_headline: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

function SponsorDashboard(props) {
  const { organization, token } = props;
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { loginWithRedirect } = useAuth0();

  const dispatch = useDispatch();

  const sponsors = useSelector(selectAllSponsors);
  const totalSponsorsCount = useSelector((state) => state.sponsors.total_count);
  const rowsPerPage = useSelector((state) => state.settings.rowsPerPage);

  const status = useSelector((state) => state.sponsors.status);

  const [pendingCreation, setPendingCreation] = useState(false);

  const loading =
    status === 'idle' || status === 'loading' || status === 'failed' || pendingCreation;

  const [dialogOpen, setDialogOpen] = useState(false);

  const headCells = [
    { id: 'id', type: 'number', disablePadding: true, label: 'Sponsor ID' },
    { id: 'company_name', type: 'string', disablePadding: false, label: 'Company Name' },
    { id: 'contact_name', type: 'string', disablePadding: false, label: 'Primary Contact' },
    { id: 'phone_number', type: 'string', disablePadding: false, label: 'Phone Number' },
    { id: 'website_url', type: 'string', disablePadding: false, label: 'Website' },
    { disablePadding: false },
  ];

  const mobileHeadCells = [
    { id: 'id', type: 'number', disablePadding: true, label: 'Sponsor ID' },
    { id: 'company_name', type: 'string', disablePadding: false, label: 'Company Name' },
    { disablePadding: false },
  ];

  const dense = false;

  const handleCreateSponsor = (
    body,
    page = 1,
    pageSize = rowsPerPage,
    sort = 'desc',
    column = 'id',
  ) => {
    setPendingCreation(true);
    try {
      dispatch(createSponsor({ token, body, enqueueSnackbar, page, pageSize, sort, column }));
      setPendingCreation(false);
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  const handleDeleteSponsors = (ids) => {
    try {
      dispatch(deleteSponsors({ token, ids, enqueueSnackbar }));
    } catch (e) {
      enqueueSnackbar(`Error: ${e}`, {
        variant: 'error',
        persist: true,
      });
      loginWithRedirect();
    }
  };

  const handleLoadSponsors = useCallback(
    (page = 1, pageSize = rowsPerPage, sort = 'desc', column = 'id') => {
      try {
        dispatch(fetchSponsors({ token, enqueueSnackbar, page, pageSize, sort, column }));
      } catch (e) {
        enqueueSnackbar(`Error: ${e}`, {
          variant: 'error',
          persist: true,
        });
        loginWithRedirect();
      }
    },
    [dispatch, enqueueSnackbar, loginWithRedirect, rowsPerPage, token],
  );

  useEffect(() => {
    if (token !== null) {
      handleLoadSponsors();
    }
  }, [handleLoadSponsors, token]);

  return (
    <Box className={classes.root}>
      <NewSponsorDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        createSponsor={handleCreateSponsor}
      />
      <Box className={classes.r_content}>
        <SubHeader
          fabTitle="New Sponsor"
          setDialogOpen={setDialogOpen}
          organization={organization}
        />
        <BrowserView>
          <DataTable
            title="All Sponsors"
            loading={loading}
            dense={dense}
            rows={sponsors}
            totalCount={totalSponsorsCount}
            headCells={headCells}
            deleteRows={handleDeleteSponsors}
            loadRows={handleLoadSponsors}
            previewUrlPrefix="/sponsors/"
            defaultSortDirection="desc"
          />
        </BrowserView>
        <MobileView>
          <DataTable
            title="All Sponsors"
            loading={loading}
            dense={dense}
            rows={sponsors}
            totalCount={totalSponsorsCount}
            headCells={mobileHeadCells}
            deleteRows={handleDeleteSponsors}
            loadRows={handleLoadSponsors}
            previewUrlPrefix="/sponsors/"
            defaultSortDirection="desc"
          />
        </MobileView>
      </Box>
    </Box>
  );
}

SponsorDashboard.propTypes = {
  organization: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default SponsorDashboard;

import React, { useEffect } from 'react';
import { Switch, Redirect, useHistory, useLocation } from 'react-router-dom';

// Misc Imports
import { useSnackbar } from 'notistack';
import { BrowserView, MobileView } from 'react-device-detect';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';

// Material UI - Core
import Box from '@material-ui/core/Box';

// Material UI - Icons
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { toggleDrawerOpen } from './features/settings/settingsSlice';
import { fetchSelfOrganizations, updateSelectedOrganizationId } from './features/auth/authSlice';

// Local Pages
import PrivateRoute from './components/PrivateRoute';
import CampaignDashboard from './features/campaigns/CampaignDashboard';
import ShowCampaign from './features/campaigns/ShowCampaign';
import EditCampaign from './features/campaigns/EditCampaign';
import OfferDashboard from './features/offers/OfferDashboard';
import ShowOffer from './features/offers/ShowOffer';
import SweepstakesDashboard from './features/sweepstakes/SweepstakesDashboard';
import ShowSweepstakes from './features/sweepstakes/ShowSweepstakes';
import SponsorDashboard from './features/sponsors/SponsorDashboard';
import ShowSponsor from './features/sponsors/ShowSponsor';

// Local Components
import Drawer from './components/Drawer';
import Header from './components/Header';
import Loading from './components/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    backgroundColor: theme.palette.accent.main,
  },
  r_root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    minHeight: '100vh',
    backgroundColor: theme.palette.accent.main,
  },
  r_switch: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

function App() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const drawerOpen = useSelector((state) => state.settings.drawerOpen);

  const status = useSelector((state) => state.auth.status);
  const organizations = useSelector((state) => state.auth.organizations);
  const selectedOrganizationId = useSelector((state) => state.auth.selectedOrganizationId);

  const selectedOrganization = organizations
    ? organizations.find((x) => x.id === selectedOrganizationId)
    : null;

  const loading = status === 'idle' || status === 'loading' || selectedOrganization === null;

  const [organizaionReloading, setOrganizaionReloading] = React.useState(false);
  const [token, setToken] = React.useState(null);

  const toggleDrawer = () => {
    dispatch(toggleDrawerOpen());
  };

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    let shouldRun = true;

    if (shouldRun) {
      shouldRun = false;
      try {
        getAccessTokenSilently()
          .then((token) => {
            dispatch(fetchSelfOrganizations({ token })).then(() => {
              getAccessTokenSilently({ ignoreCache: true }).then((token) => {
                setToken(token);
              });
            });
          })
          .catch((e) => {
            enqueueSnackbar(`Error: ${e}`, {
              variant: 'error',
              persist: true,
            });
            loginWithRedirect();
          });
      } catch (e) {
        enqueueSnackbar(`Error: ${e}`, {
          variant: 'error',
          persist: true,
        });
      }
    }

    return () => shouldRun;
  }, [dispatch, enqueueSnackbar, getAccessTokenSilently, loginWithRedirect]);

  const handleOrganizationChange = (event, newValue) => {
    setOrganizaionReloading(true);
    history.push('/');
    dispatch(updateSelectedOrganizationId(newValue.props.value));
    getAccessTokenSilently()
      .then((token) => {
        dispatch(fetchSelfOrganizations({ token })).then(() => {
          setToken(token);
          window.location.reload();
        });
      })
      .catch((e) => {
        enqueueSnackbar(`Error: ${e}`, {
          variant: 'error',
          persist: true,
        });
        loginWithRedirect();
      });
  };

  if (loading || organizaionReloading) {
    return <Loading />;
  }

  return (
    <div className={classes.root} id="app">
      <BrowserView>
        <Drawer open={drawerOpen} />
      </BrowserView>
      <Box className={classes.r_root}>
        <Header
          open={drawerOpen}
          toggleDrawer={toggleDrawer}
          handleOrganizationChange={handleOrganizationChange}
        />
        <MobileView>
          <Tabs
            value={
              location.pathname.split('/')[1] === 'campaigns'
                ? 0
                : location.pathname.split('/')[1] === 'offers'
                ? 1
                : location.pathname.split('/')[1] === 'sponsors'
                ? 2
                : location.pathname.split('/')[1] === 'sweepstakes'
                ? 3
                : 0
            }
            onChange={(_, newVal) => {
              switch (newVal) {
                case 0:
                  history.push('/campaigns');
                  break;
                case 1:
                  history.push('/offers');
                  break;
                case 2:
                  history.push('/sponsors');
                  break;
                case 3:
                  history.push('/sweepstakes');
                  break;
                default:
                  history.push('/campaigns');
              }
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Campaigns" icon={<GroupIcon />} />
            <Tab label="Offers" icon={<LocalOfferIcon />} />
            <Tab label="Sponsors" icon={<BusinessIcon />} />
            <Tab label="Sweepstakes" icon={<ConfirmationNumberIcon />} />
          </Tabs>
        </MobileView>
        <Switch className={classes.r_switch}>
          <PrivateRoute path="/" exact component={() => <Redirect to="/campaigns" />} />
          {/* Campaigns */}
          <PrivateRoute
            path="/campaigns"
            exact
            component={() => (
              <CampaignDashboard organization={selectedOrganization} token={token} />
            )}
          />
          <PrivateRoute
            path="/campaigns/:id"
            exact
            component={() => <ShowCampaign token={token} />}
          />
          <PrivateRoute
            path="/campaigns/:id/edit"
            exact
            component={() => <EditCampaign organization={selectedOrganization} token={token} />}
          />
          {/* Offers */}
          <PrivateRoute
            path="/offers"
            exact
            component={() => <OfferDashboard organization={selectedOrganization} token={token} />}
          />
          <PrivateRoute path="/offers/:id" exact component={() => <ShowOffer token={token} />} />
          {/* Sweepstakes */}
          <PrivateRoute
            path="/sweepstakes"
            exact
            component={() => (
              <SweepstakesDashboard organization={selectedOrganization} token={token} />
            )}
          />
          <PrivateRoute
            path="/sweepstakes/:id"
            exact
            component={() => <ShowSweepstakes token={token} />}
          />
          {/* Sponsors */}
          <PrivateRoute
            path="/sponsors"
            exact
            component={() => <SponsorDashboard organization={selectedOrganization} token={token} />}
          />
          <PrivateRoute
            path="/sponsors/:id"
            exact
            component={() => <ShowSponsor token={token} />}
          />
        </Switch>
      </Box>
    </div>
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loading />,
});

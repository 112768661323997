import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Material UI - Core
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  loadingRoot: {
    display: 'flex',
    flex: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.loadingRoot}>
      <CircularProgress />
    </div>
  );
}

export default Loading;

import React from 'react';
import PropTypes from 'prop-types';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flexShrink: 1,
  },
  card_loading: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 800,
  },
  row: {
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  rowBoldContent: {
    display: 'flex',
    flex: 1,
    fontSize: 14,
    fontWeight: 800,
    wordBreak: 'break-all',
  },
  rowContent: {
    display: 'flex',
    flex: 1,
    fontSize: 14,
    wordBreak: 'break-all',
  },
  rowBoldCenteredContent: {
    display: 'flex',
    flex: 1,
    fontSize: 14,
    fontWeight: 800,
    wordBreak: 'break-all',
    justifyContent: 'center',
  },
  rowCenteredContent: {
    display: 'flex',
    flex: 1,
    fontSize: 14,
    wordBreak: 'break-all',
    justifyContent: 'center',
  },
  cardPreview: {
    height: 150,
    width: 150,
    objectFit: 'contain',
    padding: theme.spacing(1),
  },
}));

function DetailCard(props) {
  const { title, loading, object, firstRowTitles, lastRowFooter, rowsCentered } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={loading ? classes.card_loading : classes.card}>
        <CardContent className={classes.row}>
          <Typography className={classes.title}>{title}</Typography>
        </CardContent>
        {loading || object === null
          ? [...Array(5).keys()].map(() => (
              <>
                <Divider />
                <CardContent className={classes.row}>
                  <Skeleton variant="text" className={classes.rowContent} />
                </CardContent>
              </>
            ))
          : // eslint-disable-next-line array-callback-return
            Object.entries(object).map((key, idx) => {
              const isTitleRow = firstRowTitles && idx === 0;
              const isFooter = lastRowFooter && idx === Object.keys(object).length - 1;
              const row = rowsCentered ? classes.rowCenteredContent : classes.rowContent;
              const boldRow = rowsCentered
                ? classes.rowBoldCenteredContent
                : classes.rowBoldContent;

              switch (typeof key[1]) {
                case 'object':
                  // if (Array.isArray(key[1]) && !String(key[0]).toLowerCase().includes('id')) {
                  //   return (
                  //     <>
                  //       <Divider />
                  //       <CardContent className={classes.row}>
                  //         <Typography
                  //           className={isTitleRow || isFooter || !firstRowTitles ? boldRow : row}
                  //         >
                  //           {key[0].includes('_') ? _.startCase(_.camelCase(key[0])) : key[0]}
                  //         </Typography>
                  //         {key[1].map((value) => {
                  //           return (
                  //             <Typography className={isTitleRow || isFooter ? boldRow : row}>
                  //               {(function () {
                  //                 switch (typeof value) {
                  //                   case 'number':
                  //                     if (Number.isNaN(Date.parse(value))) {
                  //                       return value;
                  //                     }
                  //                   case 'object':
                  //                     return null;
                  //                   default:
                  //                     return new Date(value).toLocaleDateString('en-US');
                  //                 }
                  //               })()}
                  //             </Typography>
                  //           );
                  //         })}
                  //       </CardContent>
                  //     </>
                  //   );
                  // }
                  if (key[1] !== null) {
                    return null;
                  }
                  break;
                default:
                  if (!String(key[0]).toLowerCase().includes('id')) {
                    return (
                      <>
                        <Divider />
                        <CardContent className={classes.row}>
                          <Typography
                            className={isTitleRow || isFooter || !firstRowTitles ? boldRow : row}
                          >
                            {_.startCase(_.camelCase(_.replace(key[0], '_url', '')))}
                          </Typography>
                          <Typography className={isTitleRow || isFooter ? boldRow : row}>
                            {(function () {
                              if (key[1] === null) {
                                return '-';
                              }
                              if (String(key[0]).indexOf('image') !== -1) {
                                return (
                                  <img
                                    src={String(key[1])}
                                    className={classes.cardPreview}
                                    alt={
                                      key[0].includes('_')
                                        ? _.startCase(_.camelCase(key[0]))
                                        : key[0]
                                    }
                                  />
                                );
                              }
                              if (typeof key[1] === 'number' || Number.isNaN(Date.parse(key[1]))) {
                                return key[1];
                              }
                              return new Date(key[1]).toLocaleDateString('en-US');
                            })()}
                          </Typography>
                        </CardContent>
                      </>
                    );
                  }
              }
            })}
      </Card>
    </div>
  );
}

DetailCard.defaultProps = {
  firstRowTitles: false,
  lastRowFooter: false,
  rowsCentered: false,
};

DetailCard.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  firstRowTitles: PropTypes.bool.isRequired,
  lastRowFooter: PropTypes.bool.isRequired,
  rowsCentered: PropTypes.bool.isRequired,
  object: PropTypes.object,
};

export default DetailCard;

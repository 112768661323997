import React from 'react';
import PropTypes from 'prop-types';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';

// Material UI - Icons
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textSize: '18px',
  },
  formControl: {
    marginRight: '16px',
    marginLeft: '16px',
  },
}));

function Header(props) {
  const { toggleDrawer, handleOrganizationChange } = props;
  const classes = useStyles();
  const { user, logout } = useAuth0();

  const { name } = user || '';

  const [settingsAnchorEl, setSettingsAnchorEl] = React.useState(null);

  const selectedOrganizationId = useSelector((state) => state.auth.selectedOrganizationId);
  const organizations = useSelector((state) => state.auth.organizations);

  const handleSettingsClick = (event) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Menu
        id="settings-menu"
        anchorEl={settingsAnchorEl}
        keepMounted
        open={Boolean(settingsAnchorEl)}
        onClose={handleSettingsClose}
      >
        <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>Logout</MenuItem>
      </Menu>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Hidden mdDown>
            <Tooltip title="Toggle Drawer" aria-label="toggle drawer">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Hidden>
          <Typography className={classes.title}>{name}</Typography>
          {organizations.length > 1 && (
            <FormControl className={classes.formControl}>
              <Select
                disableUnderline
                value={selectedOrganizationId}
                onChange={handleOrganizationChange}
              >
                {organizations.map((organization) => {
                  return <MenuItem value={organization.id}>{organization.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          )}
          {/* <Tooltip title="Help" aria-label="help"> */}
          {/*  <IconButton aria-label="account of current user" aria-haspopup="true" color="inherit"> */}
          {/*    <HelpIcon /> */}
          {/*  </IconButton> */}
          {/* </Tooltip> */}
          {/* <Tooltip title="Notifications" aria-label="notifications"> */}
          {/*  <IconButton aria-label="show 0 new notifications" color="inherit"> */}
          {/*    <Badge badgeContent={0} color="primary"> */}
          {/*      <NotificationsIcon /> */}
          {/*    </Badge> */}
          {/*  </IconButton> */}
          {/* </Tooltip> */}
          <Tooltip title="Settings" aria-label="settings">
            <IconButton
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              aria-controls="settings-menu"
              onClick={handleSettingsClick}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  handleOrganizationChange: PropTypes.func.isRequired,
};

export default Header;

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../../config';

const initialState = {
  status: 'idle',
  error: null,
  total_count: null,
  total_active_count: null,
  total_inactive_count: null,
  search_results: [],
  offers: [],
  current_offer: null,
};

export const fetchOffers = createAsyncThunk(
  'offers/fetchOffers',
  async ({ token, enqueueSnackbar, page, pageSize, sort, column }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${config.api.URL}/admin/offers?${new URLSearchParams({
          page,
          page_size: pageSize,
          sort,
          column,
        })}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const json = await response.json();
      const { data, total_count, total_active_count, total_inactive_count } = json;

      if (response.ok) {
        return {
          data,
          total_count,
          total_active_count,
          total_inactive_count,
        };
      }

      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const fetchOfferDetails = createAsyncThunk(
  'offers/fetchOfferDetails',
  async ({ token, enqueueSnackbar, id }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${config.api.URL}/admin/offers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();
      const { data } = json;

      if (response.ok) {
        return {
          data,
        };
      }
      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const searchOffers = createAsyncThunk(
  'offers/searchOffers',
  async ({ token, enqueueSnackbar, q, sponsor_id }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${config.api.URL}/admin/offers?${new URLSearchParams({ q, sponsor_id })}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const json = await response.json();
      const { data } = json;

      if (response.ok) {
        return { data };
      }

      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
      return rejectWithValue(response.data);
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
      return rejectWithValue(e);
    }
  },
);

export const createOffer = createAsyncThunk(
  'offers/createOffer',
  async (
    { token, body, enqueueSnackbar, page, pageSize, sort, column },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await fetch(`${config.api.URL}/admin/offers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();

      if (response.ok) {
        enqueueSnackbar('Offer Created', { variant: 'success' });
        dispatch(fetchOffers({ token, enqueueSnackbar, page, pageSize, sort, column }));
        return;
      }

      enqueueSnackbar(`Error: ${JSON.stringify(json.error.message)}`, {
        variant: 'error',
        persist: true,
      });
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
    }
  },
);

export const deleteOffers = createAsyncThunk(
  'offers/deleteOffers',
  async ({ token, ids, enqueueSnackbar }, { dispatch }) => {
    try {
      const statements = [];

      ids.forEach((id) => {
        statements.push(
          fetch(`${config.api.URL}/admin/offers/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }),
        );
      });

      await Promise.all(statements).then((responses) => {
        responses.forEach((res) => {
          if (!res.ok) {
            enqueueSnackbar(`Error: ${JSON.stringify(res.json().error.message)}`, {
              variant: 'error',
              persist: true,
            });
          }
        });
        if (statements.length === 1) {
          enqueueSnackbar(`Offer Deleted`, { variant: 'success' });
        } else {
          enqueueSnackbar(`${statements.length} Offers Deleted`, { variant: 'success' });
        }
      });

      return { ids };
    } catch (e) {
      enqueueSnackbar(String(e), { variant: 'error', persist: true });
    }
  },
);

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    resetSearchResults(state) {
      state.search_results = [];
    },
    resetCurrentOffer(state) {
      state.current_offer = null;
    },
  },
  extraReducers: {
    // fetchOffers
    [fetchOffers.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchOffers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.offers = action.payload.data;
      state.total_count = action.payload.total_count;
      state.total_active_count = action.payload.total_active_count;
      state.total_inactive_count = action.payload.total_inactive_count;
    },
    [fetchOffers.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // fetchOfferDetails
    [fetchOfferDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchOfferDetails.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.current_offer = action.payload.data;
    },
    [fetchOfferDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // searchOffers
    [searchOffers.pending]: (state) => {
      state.status = 'loading';
      state.search_results = [];
    },
    [searchOffers.fulfilled]: (state, action) => {
      if (state.status === 'loading') {
        state.search_results = action.payload.data;
      }
      state.status = 'succeeded';
    },
    [searchOffers.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // createOffer
    [createOffer.pending]: (state) => {
      state.status = 'loading';
    },
    [createOffer.fulfilled]: () => {
      // state.status = 'succeeded';
    },
    [createOffer.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // deleteOffers
    [deleteOffers.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteOffers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      console.log(action.payload.ids);
      state.offers = state.offers.filter((offer) => !action.payload.ids.includes(offer.id));
    },
    [deleteOffers.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const { resetSearchResults, resetCurrentOffer } = offersSlice.actions;
export default offersSlice.reducer;

export const selectAllOffers = (state) => state.offers.offers;

export const selectOffersSearchResults = (state) => state.offers.search_results;

export const selectOfferById = (state, offerId) =>
  state.offers.offers.find((x) => x.id.toString() === offerId);

import React, { memo, useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleQuantile } from 'd3-scale';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ReactTooltip from 'react-tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import PropTypes from 'prop-types';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

const rounded = (num) => {
  if (num > 1000000) {
    return `${Math.round(num / 100000) / 10}M`;
  }
  if (num > 1000) {
    return `${Math.round(num / 100) / 10}K`;
  }
  return Math.round(num);
};

// const data = [];
//
// for (let i = 0; i < 100; i += 1) {
//   let id;
//   if (i < 10) {
//     id = `0${String(i)}`;
//   } else {
//     id = String(i);
//   }
//   data.push({ id, value: Math.random() * 10000 });
//   // data.push({ id, value: 0 });
// }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flexShrink: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  row: {
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  r_map_container: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  i_map: {
    display: 'flex',
    flex: 1,
    width: '75%',
    height: 'auto',
  },
}));

const MapChart = ({ setTooltipContent, data }) => {
  const classes = useStyles();

  const colorScale = scaleQuantile()
    .domain(data.map((d) => d.value))
    .range([
      '#E9DFDA',
      '#D9CEC2',
      '#CAC0AB',
      '#BAB593',
      '#A7AA7C',
      '#8C9A65',
      '#6F894E',
      '#567943',
      '#3F6839',
      '#2F5832',
      '#25472F',
    ]);

  return (
    <ComposableMap data-tip="" projection="geoAlbersUsa" className={classes.i_map}>
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => {
            const cur = data.find((s) => s.id === geo.id);
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={cur && 'value' in cur ? colorScale(cur.value) : '#EEE'}
                onMouseEnter={() => {
                  const { name } = geo.properties;
                  if (cur && 'value' in cur) {
                    setTooltipContent(`${name} — ${rounded(cur.value)}`);
                  } else {
                    setTooltipContent(`${name} — 0`);
                  }
                }}
                onMouseLeave={() => {
                  setTooltipContent('');
                }}
                style={{
                  default: {
                    strokeWidth: 0.75,
                    outline: 'none',
                  },
                  hover: {
                    outline: 'none',
                  },
                  pressed: {
                    outline: 'none',
                  },
                }}
              />
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
};

MapChart.propTypes = {
  setTooltipContent: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

const MapCard = (props) => {
  const { data } = props;

  const classes = useStyles();
  const [content, setContent] = useState('');

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.row}>
          <Typography className={classes.title}>Popular Locations</Typography>
        </CardContent>
        <Divider />
        <CardContent className={classes.r_map_container}>
          <MapChart setTooltipContent={setContent} data={data} />
          <ReactTooltip>{content}</ReactTooltip>
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
};

MapCard.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(MapCard);

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

// Material UI - Lab
import Skeleton from '@material-ui/lab/Skeleton';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

function SimpleDataTableHead(props) {
  const { loading, headCells } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {loading ? <Skeleton variant="text" /> : 'label' in headCell ? headCell.label : ''}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

SimpleDataTableHead.propTypes = {
  loading: PropTypes.bool.isRequired,
  headCells: PropTypes.array.isRequired,
};

function SimpleDataTableFooter(props) {
  const { loading, footerCells } = props;

  return (
    <TableHead>
      <TableRow>
        {footerCells.map((footerCell) => (
          <TableCell
            key={footerCell.id}
            align="left"
            padding={footerCell.disablePadding ? 'none' : 'default'}
          >
            {loading ? <Skeleton variant="text" /> : 'label' in footerCell ? footerCell.label : ''}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

SimpleDataTableFooter.propTypes = {
  loading: PropTypes.bool.isRequired,
  footerCells: PropTypes.array.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    flex: '1 1 100%',
  },
}));

const SimpleDataTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
    </Toolbar>
  );
};

SimpleDataTableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
};

const useFooterToolbarStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontSize: 14,
    fontWeight: 800,
    textAlign: 'center',
  },
}));

const SimpleDataTableFooterToolbar = (props) => {
  const classes = useFooterToolbarStyles();
  const { title } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
    </Toolbar>
  );
};

SimpleDataTableFooterToolbar.propTypes = {
  title: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flexShrink: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 800,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 400,
  },
  row: {
    padding: 16,
  },
  table: {
    width: '100%',
  },
  tableContainer: {
    width: '100%',
  },
  card_loading: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  logo: {
    height: 100,
    width: 200,
    objectFit: 'contain',
  },
}));

function SimpleDataTableCard(props) {
  const {
    loading,
    dense,
    rows,
    headCells,
    footerCells,
    title,
    footerTitle,
    footerButtonHandler,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={loading ? classes.card_loading : classes.card}>
        {/* Variant 1 Start */}
        {/* <SimpleDataTableToolbar title={title} /> */}
        {/* Variant 1 End */}

        {/* Variant 2 Start */}
        <CardContent className={classes.row}>
          <Typography className={classes.title}>{title}</Typography>
        </CardContent>
        <Divider />
        {/* Variant 2 End */}
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          aria-label="data table"
        >
          <SimpleDataTableHead loading={loading} headCells={headCells} />
          <TableBody>
            {loading
              ? [...Array(rows.length).keys()].map((row) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row}>
                      {headCells.map((column) => (
                        <TableCell align="left" key={column.id}>
                          <Skeleton variant="text" />
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              : rows.map((row) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                      {headCells.map((column) => {
                        return (
                          <TableCell align="left" key={column.id}>
                            {(() => {
                              if (column.id in row && row[column.id] !== null) {
                                if ('parent' in column) {
                                  switch (column.type) {
                                    case 'date':
                                      if (
                                        row[column.parent][column.id] !== null &&
                                        row[column.parent][column.id] !== undefined
                                      ) {
                                        return new Date(
                                          row[column.parent][column.id],
                                        ).toLocaleDateString('en-US');
                                      }
                                      break;
                                    case 'number':
                                    case 'string':
                                    default:
                                      return row[column.parent][column.id];
                                  }
                                } else {
                                  switch (column.type) {
                                    case 'date':
                                      if (row[column.id] !== null && row[column.id] !== undefined) {
                                        return new Date(row[column.id]).toLocaleDateString('en-US');
                                      }
                                      break;
                                    case 'number':
                                    case 'string':
                                    default:
                                      return row[column.id];
                                  }
                                }
                              } else {
                                return '-';
                              }
                            })()}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
          {footerCells && <SimpleDataTableFooter loading={loading} footerCells={footerCells} />}
        </Table>
        {footerTitle &&
          (footerButtonHandler ? (
            <Button onClick={(event) => footerButtonHandler(event, '4')}>
              <SimpleDataTableFooterToolbar title={footerTitle} />
            </Button>
          ) : (
            <SimpleDataTableFooterToolbar title={footerTitle} />
          ))}
      </Card>
    </div>
  );
}

SimpleDataTableCard.propTypes = {
  title: PropTypes.string.isRequired,
  footerTitle: PropTypes.string,
  footerButtonHandler: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  dense: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  footerCells: PropTypes.array,
};

export default SimpleDataTableCard;

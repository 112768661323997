import React from 'react';
import PropTypes from 'prop-types';

// Material UI - Core
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Material UI - Icons
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    display: 'block',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  fab_container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
  },
  logo_container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
  },
  logo: {
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
    border: '2px solid',
    borderColor: '#ffffff',
    height: 50,
    width: 50,
    objectFit: 'contain',
    backgroundColor: '#A3A3A3',
    padding: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
  },
}));

function SubHeader(props) {
  const { fabTitle, setDialogOpen, organization } = props;
  const classes = useStyles();

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.logo_container}>
          <img
            className={classes.logo}
            src={organization && 'logo_image_url' in organization && organization.logo_image_url}
            alt={`${organization.name} Logo`}
          />
          <Typography variant="h5" component="h2" className={classes.title}>
            {organization.name}
          </Typography>
        </Box>
        <Box className={classes.fab_container}>
          <Fab variant="extended" color="primary" onClick={handleClickOpen}>
            <AddIcon className={classes.extendedIcon} />
            {fabTitle}
          </Fab>
        </Box>
      </Box>
    </Box>
  );
}

SubHeader.propTypes = {
  fabTitle: PropTypes.string.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

export default SubHeader;

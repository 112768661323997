const local = {
  api: {
    URL: 'localhost:3000',
  },
  auth0: {
    DOMAIN: 'speaqapp.auth0.com',
    CLIENT_ID: '2l15bJin24irUu5nJI5rVgHZTDRq8eGx',
    AUDIENCE: 'https://dev.api.speaqapp.com',
  },
  assets: {
    DOMAIN: 'https://dev.speaqapp.com',
  },
  pages: {
    URL: 'https://dev.pages.speaqapp.com',
  },
};

const dev = {
  api: {
    URL: 'https://dev.api.speaqapp.com',
  },
  auth0: {
    DOMAIN: 'speaqapp.auth0.com',
    CLIENT_ID: '2l15bJin24irUu5nJI5rVgHZTDRq8eGx',
    AUDIENCE: 'https://dev.api.speaqapp.com',
  },
  assets: {
    URL: 'https://dev.speaqapp.com',
  },
  pages: {
    URL: 'https://dev.pages.speaqapp.com',
  },
};

const prod = {
  api: {
    URL: 'https://api.speaqapp.com',
  },
  auth0: {
    DOMAIN: 'speaqapp.auth0.com',
    CLIENT_ID: '2l15bJin24irUu5nJI5rVgHZTDRq8eGx',
    AUDIENCE: 'https://api.speaqapp.com',
  },
  assets: {
    URL: 'https://speaqapp.com',
  },
  pages: {
    URL: 'https://pages.speaqapp.com',
  },
};

let config = {};

if (process.env.REACT_APP_STAGE === 'prod') {
  config = prod;
} else if (process.env.REACT_APP_STAGE === 'dev') {
  config = dev;
} else {
  config = local;
}

export default {
  // Add common config values here
  // MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drawerOpen: true,
  rowsPerPage: 10,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateRowsPerPage: {
      reducer(state, action) {
        state.rowsPerPage = action.payload;
      },
    },
    toggleDrawerOpen: {
      reducer(state) {
        state.drawerOpen = !state.drawerOpen;
      },
    },
  },
});

export const { updateRowsPerPage, toggleDrawerOpen } = settingsSlice.actions;

export default settingsSlice.reducer;
